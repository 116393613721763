<template>
  <v-container fluid>
      <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card class="mx-auto px-2 pt-2" :disabled="disableOtpInput">
          <v-toolbar flat dark class="indigo lighten-3">
              <v-toolbar-title>Copy & paste / type OTP; One Time Password indicated in the email received...</v-toolbar-title>
          </v-toolbar>
          <v-row>
            <v-col
              cols="12"
              xl="4"
              offset-xl="4"
              lg="6"
              offset-lg="3"
              md="6"
              offset-md="3"
              sm="8"
              offset-sm="2"
              xs="12"
              offset-xs="0"
            >
              <v-otp-input
                class="
                  font-weight-bold
                  text-uppercase
                  btn-primary
                  bg-gradient-primary
                  py-2
                  px-6
                  me-2
                  mt-7
                  mb-2
                  w-100
                "
                dark
                type="number"
                color="#5e72e4"
                v-model="otp"
                :disabled="overlay"
                @finish="onFinish"
            ></v-otp-input>
          </v-col>
        </v-row>
      </v-card>
  </v-container>
</template>
<script>
export default {
  created() {

    if(this.$store.state.isLogged && this.$store.state.user.role === 'user') {
        location.href = '/payment/' +
            this.$route.params.container_number +
            '/' +
            this.$route.params.transaction_id + '/' +
            this.$route.params.email;
    }

    this.axios.post(
      '/api/check_parameters',
      {
        container_number: this.$route.params.container_number,
        email: this.$route.params.email,
      }
    ).then(response => {
      if(response.status === 200 ) {
        if(response.data === 1) {
          this.disableOtpInput = false;
          const payload = {
              title: "Input OTP; One Time Password",
              msg: "Before going to picture taking page please input your OTP indicated in the email that you received from us...",
            };
            this.$store.commit("showAlert", payload);
        } else {
          this.disableOtpInput = true;
          const payload = {
              title: "Sorry, something is wrong...",
              msg: "You are sending wrong email...please just click the hyperlinked URL indicated on the email that we sent you...",
            };
            this.$store.commit("showAlert", payload);
        }
      }
    }).catch(error => {
      console.log(error);
    });
  },
  data() {
    return {
      overlay: false,
      disableOtpInput: true,
      password: null,
      codeErrorMsg: null,
      otp: null,
    }
  },
  methods: {
    onFinish: function() {
      this.overlay = true;

      this.axios.post(
        '/api/check_otp',
        {
          transaction_id: this.$route.params.transaction_id,
          container_number: this.$route.params.container_number,
          email: this.$route.params.email,
          otp: this.otp,
          role: "user",
          password: this.otp,
          password_confirmation: this.otp,
        }
      ).then(response => {
        // console.log(response.data);
        if(response.status === 200) {
          if(response.data === 'invalid') {
            const payload = {
              title: "OTP is invalid",
              msg: "You seemed to input wrong OTP...sorry, please try to do the correct one...",
            };
            this.$store.commit("showAlert", payload);
          } else if(response.data === 'deleted') {
            const payload = {
              title: "Transaction record deleted or does not exist",
              msg: "Your transaction has been expired or does not exist in our database...",
            };
            this.$store.commit("showAlert", payload);
          } else {
            this.$store.commit('assignAccessToken', response.data.access_token);
            this.$store.commit('logIn');
            this.$store.commit('assignUserInfo', response.data.user);

            // location.href = '/take_picture/' +
            //     this.$route.params.container_number +
            //     '/' +
            //     this.$route.params.transaction_id + '/' +
            //     this.$route.params.email;
            location.href = '/payment/' +
                this.$route.params.container_number +
                '/' +
                this.$route.params.transaction_id + '/' +
                this.$route.params.email;
          }
        }
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.overlay = false;
      });
    }
  }
}
</script>
