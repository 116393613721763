<template>
    <v-container>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title>Verify Container Information</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>TransactionId</th>
                                <th>ContainerNumber</th>
                                <th>ContainerType</th>
                                <th>ContainerSize</th>
                                <th>Destination</th>
                                <th>UserName</th>
                                <th>Email</th>
                                <th>Organization</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ transactionId }}</td>
                                <td>{{ containerNumber }}</td>
                                <td>{{ containerType }}</td>
                                <td>{{ containerSize }}</td>
                                <td>{{ destination }}</td>
                                <td>{{ userName }}</td>
                                <td>{{ email }}</td>
                                <td>{{ userOrganization }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col>
                        <v-btn
                            block
                            color="green"
                            dark
                            @click="sendLink()"
                        >
                        <v-icon>mdi-link-variant</v-icon>
                        Send URL</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn
                            block
                            color="red"
                            class="lighten-2"
                            dark
                            @click="openSelectDestinationDialog()"
                        >
                        <v-icon>mdi-file-document-remove</v-icon>
                        Reject Transaction</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <!-- selectDestinationDialog start -->
        <v-dialog v-model="selectDestinationDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Select Destination -- {{containerNumber}} --</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="selectDestinationDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-select
                            dense
                            outlined
                            :items="destinations"
                            item-text="destination_type"
                            item-value="destination_id"
                            label="Destination"
                            v-model="destinationId"
                        ></v-select>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            color="red"
                            dark
                            block
                            class="lighten-2"
                            v-if="destinationId"
                            @click="rejectTransaction()"
                        >
                            <v-icon>mdi-content-save</v-icon>
                            save
                        </v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </v-dialog>
        <!--selectDestinationDialog end-->
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            transaction: null,
            container: null,
            transactionId: null,
            email: null,
            containerNumber: null,
            containerSize: null,
            containerType: null,
            destination: null,
            userName: null,
            userOrganization: null,
            selectDestinationDialog: false,
            destinations: [],
            destinationId: null,
        }
    },
    mounted() {
        this.getTransactionWithContainerAndUser();
        this.getTransaction();
        this.getDestinations();
    },
    methods: {
        getTransaction: function() {
            this.overlay = true;
            axios.post(
                '/api/get_transaction',
                {
                    transaction_id: this.$route.params.transaction_id,
                }
            ).then(response => {
                if(response.status === 200) {
                    this.transaction = response.data;
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        getContainer: function() {
            this.overlay = true;
            axios.post(
                '/api/get_container',
                {
                    container_number: this.containerNumber,
                }
            ).then(response => {
                if(response.status === 200) {
                    this.container = response.data;
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        getDestinations: function() {
            this.overlay = true;
            axios.post(
                '/api/get_destinations'
            ).then(response => {
                if(response.status === 200) {
                    this.destinations = response.data;
                    // console.log(this.destinations)
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        getTransactionWithContainerAndUser: function() {
            this.overlay = true;
            axios.post(
                '/api/get_transaction_with_container_and_user',
                {
                    transaction_id: this.$route.params.transaction_id,
                }
            ).then(response => {
                if(response.status === 200) {
                    // console.log(response.data[0]);
                    this.transactionId = response.data[0].transaction_id;
                    this.containerNumber = response.data[0].container_number;
                    this.containerType = response.data[0].container.container_type_id;
                    this.containerSize = response.data[0].container.container_size_id;
                    this.destination = response.data[0].destination_id;
                    this.userName = response.data[0].user.name;
                    this.email = response.data[0].user.email;
                    this.userOrganization = response.data[0].user.organization;
                    this.getContainer();
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        sendLink: function() {
            this.overlay = true;
            axios.post(
                '/api/update_status_id_to_s02_and_send_url',
                {
                    transaction_id: this.$route.params.transaction_id,
                    user_id: this.$store.state.user.id,
                }
            ).then(response => {
                if(response.status === 200) {
                    // console.log(response.data);
                    this.$router.push('/transactions_for_preadvise');
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        openSelectDestinationDialog: function() {
            if(!confirm('Is it ok to reject this transaction?')) {
                return false;
            }

            this.selectDestinationDialog = true;
        },
        rejectTransaction: function() {
            this.overlay = true;
            axios.post(
                '/api/reject_transaction',
                {
                    transaction_id: this.$route.params.transaction_id,
                    container_number: this.containerNumber,
                    transaction: this.transaction,
                    container: this.container,
                    email: this.email,
                    destination_id: this.destinationId,
                }
            ).then(response => {
                if(response.status === 200) {
                    // console.log(response.data);
                    this.$router.push('/rejected_transactions');
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        }
    }
}
</script>
