<template>
    <v-container>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <!-- <v-btn @click="test">test</v-btn> -->
          <v-toolbar flat dark class="indigo lighten-3">
              <v-toolbar-title>Containers For Sino Cargoworks</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :items-per-page="itemsPerPage"
              fixed-header
              height="50vh"
              hide-default-footer
            >
            <template v-slot:top>
              <v-btn
                dark
                color="red"
                class="lighten-2 my-2"
                block
                @click="openEncodeContainerInformationDialog"
              >
                <v-icon>mdi-playlist-edit</v-icon> Encode Container Info.
              </v-btn>
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Search by container#..."
                placeholder="Search by container#..."
                outlined
                dense
                @keyup="searchByKeyword()"
              ></v-text-field>
              # of records: <span style="color: red; font-weight: bold;">{{totalNumberOfRecords}}</span>
            </template>
            <template v-slot:[`item.transactions`]="{ item }">
              {{ item.transactions | showStatuses }}
            </template>
          </v-data-table>
          <div class="text-center pt-2">
              <v-pagination
                  v-model="page"
                  :length="pageCount"
                  total-visible="10"
                  @input="changePage()"
              ></v-pagination>
          </div>
          </v-card-text>
        </v-card>
        <!--encodeContainerInformationDialog start-->
        <v-dialog v-model="encodeContainerInformationDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container fluid>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title>
                          Encode Container Information
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="encodeContainerInformationDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-form ref="form">
                        <v-card-text>
                            <v-text-field
                                outlined
                                dense
                                label="UserEmailAddress"
                                placeholder="UserEmailAddress"
                                v-model="userEmailAddress"
                                :rules="emailRules"
                            ></v-text-field>
                            <v-text-field
                                outlined
                                dense
                                label="ContainerNumber"
                                placeholder="ContainerNumber"
                                v-model="containerNumber"
                                :rules="rules"
                                @blur="avoidDuplicateEntryOfContainerNumber"
                                @keyup="upperCase"
                            ></v-text-field>
                            <v-select
                                outlined
                                dense
                                label="ContainerSize"
                                placeholder="ContainerSize"
                                v-model="containerSizeId"
                                :rules="rules"
                                :items="container_sizes"
                                item-text="size"
                                item-value="container_size_id"
                            ></v-select>
                            <v-select
                                outlined
                                dense
                                label="ContainerType"
                                placeholder="ContainerType"
                                v-model="containerTypeId"
                                :rules="rules"
                                :items="container_types"
                                item-text="type"
                                item-value="container_type_id"
                            ></v-select>
                            <v-select
                                outlined
                                dense
                                label="Destination"
                                placeholder="Destination"
                                v-model="destinaionId"
                                :rules="rules"
                                :items="destinations"
                                item-text="destination_type"
                                item-value="destination_id"
                            ></v-select>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="red"
                                dark
                                @click="saveContainerInformation"
                                :disabled="disableSaveContainerInformationButton"
                            >
                                <v-icon>mdi-content-save</v-icon>
                                <v-icon>mdi-send</v-icon>
                                save & send
                            </v-btn>
                            <v-btn
                                @click="encodeContainerInformationDialog = false"
                            >cancel</v-btn>
                        </v-card-actions>
                    </v-form>
                </v-container>
            </v-card>
        </v-dialog>
        <!--encodeContainerInformationDialog finish-->
      </v-container>
</template>
<script>
export default {
  data() {
    return {
      overlay: false,
      loading: false,
      search: null,
      pageCount: 0,
      page: 1,
      itemsPerPage: 30,
      totalNumberOfRecords: null,
      disableSaveContainerInformationButton: false,
      headers: [
        {
          text: "container#",
          value: "container_number",
          sortable: true,
        },
        {
          text: "company",
          value: "company.name",
          sortable: true,
        },
        {
          text: "size",
          value: "size.size",
          sortable: true,
        },
        {
          text: "type",
          value: "type.type",
          sortable: true,
        },
        {
          text: "tare_weight",
          value: "tare_weight",
          sortable: true,
        },
        {
          text: "mass_gross_weight",
          value: "mass_gross_weight",
          sortable: true,
        },
        {
          text: "status",
          value: "transactions",
          sortable: true,
        },
      ],

      items: [],
      encodeContainerInformationDialog: false,
      userEmailAddress: null,
      containerNumber: null,
      containerSizeId: null,
      containerTypeId: null,
      destinaionId: null,
      rules: [(v) => !!v || "Required"],
      emailRules: [
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
      ],
      container_sizes: [],
      container_types: [],
      destinations: [],
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    test: function() {
        this.axios.post(
            '/api/test',
            {
                container_number: 'AAAAAAAAAA'
            }
        ).then(response => {
            // console.log(reponse.data);
        }).catch(error => {
            console.log(error);
        });
    },
    loadData: function() {
      this.overlay = true;
      this.loading = true;
      this.axios.post(
        '/api/get_containers',
        {
            company_id: this.$store.state.user.company_id,
            page: this.page,
            itemsPerPage: this.itemsPerPage,
            search: this.search
        }
      ).then(response => {
          this.items = response.data.data;
          this.totalNumberOfRecords = response.data.total;
          this.pageCount = Math.ceil(response.data.total / this.itemsPerPage);
        }).catch(error => {
          console.log(error);
        }).finally(() => {
          this.overlay = false;
          this.loading = false;
        });
    },
    loadContainerTypes: function() {
      this.axios.post('/api/get_container_types')
            .then(response => {
                this.container_types = response.data;
            }).catch(error => {
                console.log(error);
            });
    },
    openEncodeContainerInformationDialog: function() {
        this.encodeContainerInformationDialog = true;
        this.userEmailAddress = null;
        this.containerNumber = null;
        this.containerSizeId = null;
        this.containerTypeId = null;
        this.destinaionId = null;

        this.axios.post('/api/get_container_sizes')
                .then(response => {
                    this.container_sizes = response.data;
                }).catch(error => {
                    console.log(error);
                });
        this.loadContainerTypes();
        this.axios.post('/api/get_destinations')
            .then(response => {
                this.destinations = response.data;
            }).catch(error => {
                console.log(error);
            });
    },
    saveContainerInformation: function() {
        if (!this.$refs.form.validate()) {
            return false;
        }
        this.overlay = true;
        this.loading = true;
        this.disableSaveContainerInformationButton = true;

        this.axios.post(
            '/api/save_container_for_original_flow',
            {
            created_by: this.$store.state.user.id,
            // company_id: this.$store.state.user.company_id,
            company_id: 'CLI-0017',
            email: this.userEmailAddress,
            container_number: this.containerNumber,
            container_size_id: this.containerSizeId,
            container_type_id: this.containerTypeId,
            destination_id: this.destinaionId
            }
        ).then(response => {
            // console.log('resu');
            // console.log(response.data);
            if(response.data === 'Container Saved!') {
                this.encodeContainerInformationDialog = false;
                this.disableSaveContainerInformationButton = false;
                const payload = {
                    title: "Container Saved!",
                    msg: "The information has been successfully saved in our database...",
                };
                this.$store.commit("showAlert", payload);
                this.loadData();
            } else {
                this.encodeContainerInformationDialog = false;
                const payload = {
                    title: "Error!",
                    msg: "Duplicate Entry Error...the transaction_id is already existing in the database table. Kindly contact with the system support...",
                };
                this.$store.commit("showAlert", payload);
            }
      }).catch(error => {
        // console.log('era');
        // console.log(error);
      }).finally(() => {
        this.overlay = false;
        this.loading = false;
      });

    },
    avoidDuplicateEntryOfContainerNumber: function() {
        this.loading = true;
        this.overlay = true;

        this.axios.post(
            '/api/avoid_duplicate_entry_of_container_number',
            {
                container_number: this.containerNumber
            }
        ).then(response => {
            // console.log(response.data)
            if(response.status === 200) {
                if(response.data.Error) {
                    const payload = {
                        title: "[Error]Container Number Duplicate Entry",
                        msg: response.data.Error,
                    };
                    this.$store.commit("showAlert", payload);
                    this.containerNumber = null;
                }
            }
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            this.loading = false;
            this.overlay = false;
        });
    },

    upperCase: function() {
        if(!this.containerNumber) {
            return false;
        }
        this.containerNumber = this.containerNumber.toUpperCase();
        if( this.containerNumber.match(/[^A-Z0-9]/g) ) {
            this.containerNumber = this.containerNumber.replace(/[^A-Z0-9]/g, "");
        }
    },
    changePage: function() {
        this.loadData();
    },
    searchByKeyword: function() {
        this.page = 1;
        this.loadData();
    }
  },
  filters: {
    showStatuses: function(value) {
      let statuses = [];
      for( var s of value ) {
        statuses.push(s.status_id);
      }
      return statuses.join(' | ');
    }
  },
  watch: {
    containerSizeId: function() {
    //   console.log(this.containerSizeId);
      if(this.containerSizeId === 'CS-003') {
        this.container_types = [
          {
            type: "High Cube",
            container_type_id: "CT-002"
          }
        ];
      } else {
        this.loadContainerTypes();
      }
    }
  }
}
</script>
