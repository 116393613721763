<template>
    <v-container>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title>Rejected Transactions</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="transactions"
                    :loading="loading"
                    :items-per-page="itemsPerPage"
                    fixed-header
                    height="50vh"
                    hide-default-footer
                >
                    <template v-slot:top>
                        # of records: <span style="color: red; font-weight: bold;">{{totalNumberOfRecords}}</span>
                    </template>
                    <template v-slot:[`item.created_at`]="{ item }">
                        {{ item.created_at | formatDate }}
                    </template>
                    <template v-slot:[`item.updated_at`]="{ item }">
                        {{ item.updated_at | formatDate }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon
                            color="red"
                            @click="deleteRejectedTransaction(item)"
                        >mdi-delete</v-icon>
                    </template>
                </v-data-table>
                <div class="text-center pt-2">
                    <v-pagination
                        v-model="page"
                        :length="pageCount"
                        total-visible="10"
                        dark
                        @input="changePage()"
                    ></v-pagination>
                </div>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            loading: false,
            headers: [
                {
                    text: "transaction_id",
                    value: "transaction_id",
                    sortable: false,
                },
                {
                    text: "container#",
                    value: "container_number",
                    sortable: false,
                },
                {
                    text: "DestinationType",
                    value: "destination.destination_type",
                    sortable: false,
                },
                {
                    text: "created_by",
                    value: "created",
                    sortable: false,
                },
                {
                    text: "created_at",
                    value: "created_at",
                    sortable: false,
                },
                {
                    text: "updated_by",
                    value: "updated_by.email",
                    sortable: false,
                },
                {
                    text: "updated_at",
                    value: "updated_at",
                    sortable: false,
                },
                {
                    text: "last_surveyor",
                    value: "last_surveyor",
                    sortable: false,
                },
                {
                    text: "progress",
                    value: "progress",
                    sortable: false,
                },
                {
                    text: "pictures",
                    value: "pictures",
                    sortable: false,
                },
                {
                    text: "download ZIP",
                    value: "download_zip",
                    sortable: false,
                },
                {
                    text: "Link",
                    value: "link",
                    sortable: false,
                },
                {
                    text: "Actions",
                    value: "actions",
                    sortable: false,
                },
            ],
            transactions: [],
            itemsPerPage: 30,
            page: 1,
            pageCount: 0,
            totalNumberOfRecords: null,

        }
    },
    mounted() {
        this.loadTransactions()
    },
    methods: {
        loadTransactions: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/get_rejected_transactions',
                {
                    user: this.$store.state.user,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage,
                    // search: this.search,
                    // dates: this.dates
                }
            ).then(response => {
                // console.log(response.data.data);
                if(response.status === 200) {
                    this.transactions = response.data.data;
                    this.totalNumberOfRecords = response.data.total;
                    this.pageCount = Math.ceil(response.data.total / this.itemsPerPage);
                }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        },
        changePage: function() {
            this.loadTransactions();
        },
        deleteRejectedTransaction: function(item) {
            if( !confirm('Is it ok to delete the record?') ) {
                return false;
            }

            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/delete_rejected_transaction',
                {
                    item: item,
                }
            ).then(response => {
                console.log(response.data);
                if(response.status === 200) {
                    this.loadTransactions();
                }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        }
    },
    filters: {
        formatDate: function (date) {
            return date !== null ? moment(date).format("MM-DD-YYYY hh:mm:ss A") : '';
        },
    },
}
</script>
