<template>
    <v-container>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title>Transactions</v-toolbar-title>
            </v-toolbar>
                <v-card-text>
                    <v-btn
                        color="warning"
                        block
                        dark
                        class="my-2"
                        @click="copyFollowupTransactions()"
                    >
                    Copy Followup Transactions
                    </v-btn>
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :loading="loading"
                    >
                        <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at | formatDate }}
                            </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{on}">
                                    <v-icon
                                        color="blue"
                                        size="30"
                                        v-on="on"
                                        @click="sendFollowupEmail(item)"
                                    >mdi-email-fast</v-icon>
                                </template>
                                <span>Send Followup Email</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            loading: false,
            headers: [
                {
                    text: "TransactionId",
                    value: "transaction_id",
                    sortable: true,
                },
                {
                    text: "ContainerNumber",
                    value: "container_number",
                    sortable: true,
                },
                {
                    text: "Email",
                    value: "email",
                    sortable: true,
                },
                {
                    text: "PIN",
                    value: "pin",

                    sortable: true,
                },
                {
                    text: "CreatedAt",
                    value: "created_at",
                    sortable: true,
                },
                {
                    text: "Actions",
                    value: "actions",
                    sortable: false,
                },
            ],
            items: []
        }
    },
    created() {
        this.getFollowupEmails();
    },
    methods: {
        copyFollowupTransactions: function() {
            this.overlay = true;
            this.loading = true;
            axios.post('/api/copy_followup_transactions')
                .then(response => {
                    this.getFollowupEmails();
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        },
        getFollowupEmails: function() {
            this.overlay = true;
            this.loading = true;
            axios.post('/api/get_followup_emails')
                .then(response => {
                    this.items = response.data;
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        },
        sendFollowupEmail: function(item) {
            if(!confirm('Is it ok to send the email?')) {
                return false;
            }

            this.overlay = true;
            this.loading = true;

            axios.post(
                '/api/send_followup_email',
                {
                    item: item
                }
            ).then(response => {
                console.log(response.data);
                this.getFollowupEmails();
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });

        }
    },
    filters: {
        formatDate: function (date) {
            return date !== null ? moment(date).format("MM-DD-YYYY hh:mm:ss A") : '';
        }
    },
}
</script>
