<template>
  <div>
    <v-container>
      <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card class="mx-auto px-2 pt-2">
          <v-toolbar flat dark class="indigo lighten-3">
              <v-toolbar-title>Sales</v-toolbar-title>
          </v-toolbar>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="areas"
            :loading="loading"
            :search="search"
            :items-per-page="30"
            fixed-header
            height="50vh"
            hide-default-footer
            :page.sync="page"
            @page-count="pageCount = $event"
          >
            <template v-slot:top>
                <v-text-field
                    class="my-2"
                    prepend-inner-icon="mdi-magnify"
                    v-model="search"
                    placeholder="Search by text..."
                    outlined
                    dense
                ></v-text-field>
              </template>
          </v-data-table>
          <div class="text-center pt-2">
              <v-pagination
                  v-model="page"
                  :length="pageCount"
                  total-visible="10"
              ></v-pagination>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      overlay: false,
      loading: false,
      headers: [
        {
          text: "AreaId",
          value: "area_id",
          sortable: true,
        },
        {
          text: "ContainerSizeId",
          value: "container_size_id",
          sortable: true,
        },
        {
          text: "AreaCode",
          value: "area_code",
          sortable: true,
        },
        {
          text: "ContainerTypeId",
          value: "container_type_id",
          sortable: true,
        },
        {
          text: "AreaName",
          value: "area_name",
          sortable: true,
        },
        {
          text: "AreaType",
          value: "area_type",
          sortable: true,
        },
        {
          text: "Remark",
          value: "remark",
          sortable: true,
        },
        {
          text: "Skip",
          value: "skip",
          sortable: true,
        },
        {
          text: "Thumbnail",
          value: "thumbnail",
          sortable: true,
        },
      ],
      areas: [],
      search: null,
      page: 1,
      pageCount: 0
    }
  },
  mounted() {
    this.getAllAreas();
  },
  methods: {
    getAllAreas: function() {
      this.overlay = true;
      this.loading = true;
      this.axios.post(
        '/api/get_all_areas',
      ).then(response => {
        if(response.status === 200) {
          this.areas = response.data;
        }
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.overlay = false;
        this.loading = false;
      });
    }
  }
}
</script>
