<template>
  <v-app app>
    <v-main>
        <app-header></app-header>
        <app-drawer></app-drawer>
        <app-footer></app-footer>
        <app-alert></app-alert>
        <router-view></router-view>
    </v-main>
  </v-app>
</template>
<script>
import Header from './components/Header.vue';
import Drawer from './components/Drawer.vue';
import Footer from './components/Footer.vue';
import Alert from './components/Alert.vue';

export default {
    components: {
        'app-header': Header,
        'app-drawer': Drawer,
        'app-footer': Footer,
        'app-alert': Alert,
    },
    created() {
        this.$store.commit('hideAlert');
    }
}
</script>
