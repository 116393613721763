<template>
  <div>
    <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card flat>
      <v-container>
        <v-toolbar flat dark class="indigo lighten-3">
            <v-toolbar-title
            >Payment Process Information</v-toolbar-title
            >
        </v-toolbar>
        <v-card-text>
          <v-simple-table dark>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Transaction ID</th>
                  <th>Reference#</th>
                  <th>Status</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{$route.query.txnid}}</td>
                  <td>{{$route.query.refno}}</td>
                  <td>{{merchantStatuses[$route.query.status]}}</td>
                  <td>{{$route.query.message}}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            block
            dark
            :disabled="disableGoToNextButton"
            @click="goToNext"
          >
            Go to next
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      overlay: false,
      disableGoToNextButton: true,
      merchantStatuses: {
        'P': 'Unpaid+',
        'U': 'Unpaid',
        'F': 'Payment_Failed',
        'S': 'Paid',
        'V': 'Cancelled',
        'R': 'Reversed',
      }
    }
  },
  created() {
    if (this.$route.query.status === "F") {
      const payload = {
        title: "Technical Error",
        msg: "The payment process failed. Kindly inform the matter to system administrator...",
      };
      this.$store.commit("showAlert", payload);
    } else if (this.$route.query.status === "S") {
      const payload = {
        title: "Payment successfully done!",
        msg: "Now you can take and upload pictures of the container...",
      };
      this.$store.commit("showAlert", payload);
      this.disableGoToNextButton = false;
    } else {
      const payload = {
        title: "Payment process ongoing...",
        msg: "You need to finish the payment process first...",
      };
      this.$store.commit("showAlert", payload);
      this.disableGoToNextButton = false;
    }
  },
  methods: {
    goToNext: function() {
      this.overlay = true;
      this.axios.post(
        '/api/get_payment2',
        {
          refno: this.$route.query.refno,
        }
      ).then(response => {
        if(response.status === 200) {
          // console.log(response.data);
          const p = response.data[0];
          const payload = {
            title: "Redirecting to picture taking page...",
            msg: "You will go to the picture taking page...",
          };
          this.$store.commit("showAlert", payload);
          location.href = `/take_picture/${p.description}/${p.txnid.substring(4)}/${p.email}`;
        }
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.overlay = false;
      });
    }
  }
};
</script>
