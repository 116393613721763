<template>
    <v-container>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title>Statistics</v-toolbar-title>
            </v-toolbar>

            <v-tabs
            v-model="tab0"
            dark
            grow
            >
            <v-tab>Page#1</v-tab>
            <v-tab>Page#2</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab0">
                <v-tab-item>
                    <!-- Page#1 start-->                     
                    <v-card class="mx-auto px-2 pt-2">
                    <!--Data Picker start-->
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-if="$store.state.user.role == 'su'"
                                v-model="month"
                                label="Month"
                                prepend-icon="mdi-calendar"
                                outlined
                                dense
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                @click="month = ''"
                                class="my-2"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="month"
                            :allowed-dates="allowedMonths"
                            type="month"
                        ></v-date-picker>
                    </v-menu>
                    <!--Data Picker finish-->                        
                        <!-- Tab start -->
                        <v-tabs
                            v-model="tab"
                            center-active
                            dark
                        >
                        <v-tabs-slider color="yellow"></v-tabs-slider>
                        <v-tab
                            v-for="(status, i) in statuses"
                            :key="i"
                        >
                            {{status}}
                        </v-tab>
                        </v-tabs>                         
                        <!-- Tab finish -->            
                        <v-card-text>
                            <v-btn
                                color="green"
                                block
                                class="white--text"
                                @click="downloadExcel1()"
                            >
                            <v-icon>mdi-microsoft-excel</v-icon>
                            download Excel</v-btn>
                            <p>*Monthly Total: <span  class="red--text font-weight-bold">{{ monthlyTotal }}</span></p>
                            <v-card-title>
                                [Dayly Summary]
                            </v-card-title>
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Count</th>
                                        <th>Detail</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(txn, i) in transactions"
                                        :key="i"
                                    >
                                        <td>{{ txn.date }}</td>
                                        <td>{{ txn.count }}</td>
                                        <td>
                                            <v-simple-table>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Count</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(detail, j) in details[txn['date']]"
                                                        :key="j"                                        
                                                    >
                                                        <td>{{ detail.name }}</td>
                                                        <td>{{ detail.emailaddress }}</td>
                                                        <td>{{ detail.count }}</td>
                                                    </tr>
                                                </tbody>
                                            </v-simple-table>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                    <!-- Page#1 end -->
                </v-tab-item>
                <v-tab-item>
                    <!-- Page#2 start -->
                    <v-card class="mx-auto px-2 pt-2">
                        <!--Data Picker start-->
                        <v-menu
                            ref="menu"
                            v-model="menu2"
                            :close-on-content-click="true"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-if="$store.state.user.role == 'su'"
                                    v-model="month2"
                                    label="Month"
                                    prepend-icon="mdi-calendar"
                                    outlined
                                    dense
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="month2 = ''"
                                    class="my-2"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="month2"
                                :allowed-dates="allowedMonths"
                                type="month"
                            ></v-date-picker>
                        </v-menu>
                        <!--Data Picker finish-->                
                        <!-- Tab start -->
                        <v-tabs
                            v-model="tab2"
                            center-active
                            dark
                        >
                        <v-tabs-slider color="yellow"></v-tabs-slider>
                        <v-tab
                            v-for="(status, i) in statuses"
                            :key="i"
                        >
                            {{status}}
                        </v-tab>
                        </v-tabs>                         
                        <!-- Tab finish -->                            
                            <v-btn
                                color="green"
                                block
                                class="white--text my-2"
                                @click="downloadExcel2()"
                            >
                            <v-icon>mdi-microsoft-excel</v-icon>
                            download Excel</v-btn>                         
                        <v-card-text>
                            <v-simple-table v-if="tab2 == 0">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>created_at</th>
                                        <th>diff</th>
                                        <th>container_verified_at</th>
                                        <th>diff</th>
                                        <th>email_sent_at</th>
                                        <th>diff</th>
                                        <th>submitted_at</th>
                                        <th>diff</th>
                                        <th>preadvised_at</th>
                                        <th>diff</th>
                                        <th>viewed_at</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(txn, i) in transactions2"
                                        :key="i"
                                    >
                                        <td>{{ txn.name }}</td>
                                        <td>{{ txn.email }}</td>
                                        <td>{{ txn.created_at | formatDate }}</td>
                                        <td>{{ getDiff(txn.created_at, txn.container_verified_at) }}</td>
                                        <td>{{ txn.container_verified_at | formatDate  }}</td>
                                        <td>{{ getDiff(txn.container_verified_at, txn.email_sent_at) }}</td>
                                        <td>{{ txn.email_sent_at | formatDate  }}</td>
                                        <td>{{ getDiff(txn.email_sent_at, txn.submitted_at) }}</td>
                                        <td>{{ txn.submitted_at | formatDate  }}</td>
                                        <td>{{ getDiff(txn.submitted_at, txn.preadvised_at) }}</td>
                                        <td>{{ txn.preadvised_at | formatDate  }}</td>
                                        <td>{{ getDiff(txn.preadvised_at, txn.viewed_at) }}</td>
                                        <td>{{ txn.viewed_at | formatDate  }}</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                            <v-simple-table v-if="tab2 == 1">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>created_at</th>
                                        <th>diff</th>
                                        <th>email_sent_at</th>
                                        <th>diff</th>
                                        <th>submitted_at</th>
                                        <th>diff</th>
                                        <th>viewed_at</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(txn, i) in transactions2"
                                        :key="i"
                                    >
                                        <td>{{ txn.name }}</td>
                                        <td>{{ txn.email }}</td>
                                        <td>{{ txn.created_at | formatDate }}</td>
                                        <td>{{ getDiff(txn.created_at, txn.email_sent_at) }}</td>
                                        <td>{{ txn.email_sent_at | formatDate  }}</td>
                                        <td>{{ getDiff(txn.email_sent_at, txn.submitted_at) }}</td>
                                        <td>{{ txn.submitted_at | formatDate  }}</td>
                                        <td>{{ getDiff(txn.submitted_at, txn.viewed_at) }}</td>
                                        <td>{{ txn.viewed_at | formatDate  }}</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>                     
                    <!-- Page#2 end -->
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            tab0: 1,
            overlay: false,
            loading: false,
            menu: false,
            menu2: false,
            month: `${moment().format('YYYY')}-${moment().format('MM')}`,
            month2: `${moment().format('YYYY')}-${moment().format('MM')}`,
            monthlyTotal: 0,
            headers: [
                {
                    text: 'Date',
                    value: 'date',
                    sortable: true
                },
                {
                    text: 'Count',
                    value: 'count',
                    sortable: true
                },
            ],
            transactions: [],
            transactions2: [],
            itemsPerPage: 50,
            pageCount: 0,
            page: 1,
            tab: 0,
            tab2: 0,
            statuses: [
                'preadvise',
                'non-preadvise'
            ],
            details: [],
        }
    },
    mounted() {
        this.loadTransactions2();
    },
    methods: {
        allowedMonths: function(value) {
            if( this.tab == 1 ) {
                return value >= '2022-12' && value <= `${moment().format('YYYY')}-${moment().format('MM')}`;
            } else if( this.tab == 0) {
                return value >= '2023-09' && value <= `${moment().format('YYYY')}-${moment().format('MM')}`;
            }
        },
        loadTransactions: function() {
            this.loading = true;
            this.overlay = true;
            this.axios.post(
                '/api/get_transactions_by_status_id_and_submitted_at',
                {
                    tab: this.tab,
                    month: this.month,
                }
            ).then(response => {
                // console.log(response.data);
                this.transactions = response.data.txns;
                this.details = response.data.details;
                this.transactions.forEach( (txn) => {
                    this.monthlyTotal += txn.count;
                });
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.loading = false;
                this.overlay = false;
            });
        },
        loadTransactions2: function() {
            this.loading = true;
            this.overlay = true;
            this.axios.post(
                '/api/get_transactions2',
                {
                    tab: this.tab2,
                    month: this.month2,
                }
            ).then(response => {
                console.log(response.data);
                this.transactions2 = response.data.txns;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.loading = false;
                this.overlay = false;
            });
        },
        getDiff: function(date1, date2) {
            if(date1 && date2) {

                const start = moment(date1);
                const end = moment(date2);

                const duration = end.diff(start);
                let hours = Math.floor(duration / (1000 * 60 * 60));
                let minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
                let seconds = Math.floor((duration % (1000 * 60)) / 1000);

                hours = String(hours).padStart(2, '0');
                minutes = String(minutes).padStart(2, '0');
                seconds = String(seconds).padStart(2, '0');

                return `${hours}h:${minutes}m:${seconds}s`;
            } else {
                return '';
            }
        },
        downloadExcel1: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/download_excel1',
                {
                    tab: this.tab,
                    month: this.month
                }
            ).then(response => {
                const fileLink = document.createElement("a");
                fileLink.href = response.data['file'];
                if( this.tab == 0 ) {
                    fileLink.download = 'StatisticsPage1Preadvise_' + moment().format('YYYYMMDD') +'.xlsx';
                } else if( this.tab == 1 ) {
                    fileLink.download = 'StatisticsPage1Non-Preadvise_' + moment().format('YYYYMMDD') +'.xlsx';
                }
                fileLink.target = "_blank";
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        downloadExcel2: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/download_excel2',
                {
                    tab: this.tab2,
                    month: this.month2
                }
            ).then(response => {
                const fileLink = document.createElement("a");
                fileLink.href = response.data['file'];
                if( this.tab == 0 ) {
                    fileLink.download = 'StatisticsPage2Preadvise_' + moment().format('YYYYMMDD') +'.xlsx';
                } else if( this.tab == 1 ) {
                    fileLink.download = 'StatisticsPage2Non-Preadvise_' + moment().format('YYYYMMDD') +'.xlsx';
                }
                fileLink.target = "_blank";
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        }
    },
    watch: {
        tab: function(value) {
            if( !this.month ) {
                return false;
            }
            this.details = [];
            this.monthlyTotal = 0;
            this.loadTransactions();
        },
        tab2: function(value) {
            if( !this.month2 ) {
                return false;
            }
            this.loadTransactions2();
        },
        tab0: function(value) {
            if( !this.month ) {
                return false;
            }
            if( !this.month2 ) {
                return false;
            }

            if( this.tab0 == 0 ) {
                this.details = [];
                this.monthlyTotal = 0;
                this.loadTransactions();
            } else if( this.tab0 == 1 ) {
                this.loadTransactions2();
            }


        },
        month: function(value) {
            if( !this.month ) {
                return false;
            }
            this.monthlyTotal = 0;
            this.details = [];
            this.loadTransactions();
        },
        month2: function(value) {
            if( !this.month2 ) {
                return false;
            }
            this.loadTransactions2();
        }
    },
    filters: {
        formatDate: function (date) {
            return date !== null ? moment(date).format("MM-DD-YYYY hh:mm:ss A(ddd)") : '';
        },
    },    
}
</script>