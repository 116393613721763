<template>
    <div>
        <v-container>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card class="mx-auto px-2 pt-2">
                <v-toolbar flat dark class="indigo lighten-3">
                    <v-toolbar-title>Payment Maintenance</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-data-table
                    :headers="paymentHeaders"
                    :items="payments"
                    :loading="loading"
                    :items-per-page="itemsPerPage"
                    :search="search"
                    fixed-header
                    >
                    <template v-slot:top>
                      <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title>Payments Table</v-toolbar-title>
                      </v-toolbar>
                      # of records: <span style="color: red; font-weight: bold;">{{ totalNumberOfRecords }}</span>
                      <v-text-field
                            class="my-2"
                            prepend-inner-icon="mdi-magnify"
                            v-model="search"
                            outlined
                            placeholder="Search by container#"
                            dense
                            @keyup="searchByKeyword()"
                        ></v-text-field>
                        <v-btn 
                            block 
                            color="red lighten-3" 
                            dark
                            v-if="transactions.length > 0 && transactions[0].transaction_id"
                            @click="createPayment()"
                        >
                            Create Payment
                        </v-btn>
                    </template>
                    <template v-slot:[`item.created_at`]="{ item }">
                      {{item.created_at | formatDate}}
                    </template>
                    <template v-slot:[`item.updated_at`]="{ item }">
                            {{item.created_at | formatDate}}
                        </template>                    
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            color="orange"
                            @click="openUpdatePaymentDialog(item)"
                          >mdi-pencil</v-icon>
                        </template>
                        <span>Update Container</span>
                      </v-tooltip>
                    </template>
                </v-data-table>
                </v-card-text>
                <v-card-text>
                    <v-data-table
                        :headers="transactionHeaders"
                        :items="transactions"
                        :loading="loading"
                        :items-per-page="itemsPerPage2"
                        :search="search"
                        fixed-header
                        >
                        <template v-slot:top>
                        <v-toolbar flat dark class="indigo lighten-3">
                            <v-toolbar-title>Transactions Table</v-toolbar-title>
                        </v-toolbar>
                        # of records: <span style="color: red; font-weight: bold;">{{ totalNumberOfRecords2 }}</span>
                        <v-text-field
                                class="my-2"
                                prepend-inner-icon="mdi-magnify"
                                v-model="search"
                                outlined
                                placeholder="Search by container#"
                                dense
                                @keyup="searchByKeyword()"
                            ></v-text-field>
                        </template>
                        <template v-slot:[`item.created_at`]="{ item }">
                            {{item.created_at | formatDate}}
                        </template>
                        <template v-slot:[`item.updated_at`]="{ item }">
                            {{item.created_at | formatDate}}
                        </template>                                                
                    </v-data-table>
                </v-card-text>                
            </v-card>
        <!-- openUpdatePaymentDialog start -->
        <v-dialog v-model="updatePaymentDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Payment -- {{search}} --</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="updatePaymentDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-form ref="form">
                        <v-card-text>    
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        outlined
                                        dense
                                        placeholder="TransactionIdPrefix"
                                        label="TransactionIdPrefix"
                                        v-model="currentTransactionIdPrefix"
                                        :rules="rules"
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        outlined
                                        dense
                                        placeholder="TransactionId"
                                        label="TransactionId"
                                        v-model="currentTransactionId"
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        outlined
                                        dense
                                        placeholder="ContainerNumber"
                                        label="ContainerNumber"
                                        v-model="currentContainerNumber"
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        outlined
                                        dense
                                        placeholder="RefNo"
                                        label="RefNo"
                                        v-model="currentRefNo"
                                        :rules="rules"
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        outlined
                                        dense
                                        placeholder="Email"
                                        label="Email"
                                        v-model="currentEmail"
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                block
                                color="red lighten-3"
                                dark
                                @click="updatePayment()"
                            >
                                <v-icon>mdi-update</v-icon> Update
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-container>
            </v-card>
        </v-dialog>
        <!-- openUpdatePaymentDialog end -->        
        </v-container>
    </div>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            loading: false,
            itemsPerPage: 30,
            pageCount: 0,
            page: 1,
            search : '',
            totalNumberOfRecords: 0,
            itemsPerPage2: 30,
            pageCount2: 0,
            page2: 1,
            totalNumberOfRecords2: 0,
            paymentHeaders: [
                {
                    text: "Id",
                    value: "id",
                    sortable: true,
                },                
                {
                    text: "TxnId",
                    value: "txnid",
                    sortable: true,
                },                
                {
                    text: "RefNo",
                    value: "refno",
                    sortable: true,
                },                
                {
                    text: "Email",
                    value: "email",
                    sortable: true,
                },                
                {
                    text: "Status",
                    value: "status",
                    sortable: true,
                },                
                {
                    text: "Description",
                    value: "description",
                    sortable: true,
                },                                
                {
                    text: "CreatedAt",
                    value: "created_at",
                    sortable: true,
                },                                
                {
                    text: "UpdatedAt",
                    value: "updated_at",
                    sortable: true,
                },      
                {
                    text: "Actions",
                    value: "actions",
                    sortable: false
                },                                          
            ],
            transactionHeaders: [
                {
                    text: "TransactionId",
                    value: "transaction_id",
                    sortable: false
                },
                {
                    text: "ContainerNumber",
                    value: "container_number",
                    sortable: false
                },
                {
                    text: "Email",
                    value: "email",
                    sortable: false
                },
                {
                    text: "StatusId",
                    value: "status_id",
                    sortable: false
                },
                {
                    text: "CreatedAt",
                    value: "created_at",
                    sortable: false
                },
                {
                    text: "UpdatedAt",
                    value: "updated_at",
                    sortable: false
                },
            ],
            transactions: [],            
            payments: [],
            updatePaymentDialog: false,
            currentId: null,
            currentContainerNumber: null,
            currentTransactionIdPrefix: null,
            currentTransactionId: null,
            currentRefNo: null,
            currentEmail: null,
            isOkToUpdatePayment: false,
            mode: null,
            rules: [(v) => !!v || "Required"],
        }
    },
    methods: {
        searchByKeyword: function() {

            if( !this.search.match(/^[A-Z]{4}[0-9]{6}[A-Z0-9]{1}$/)) {
                this.payments = [];
                this.transactions = [];
                return false;
            }
            this.page = 1;
            this.searchPaymentByContainerNumber();
            this.searchTransactionByContainerNumber();
        },        
        searchPaymentByContainerNumber: function() {
            this.overlay = true;
            this.loading = true;
            axios.post(
                '/api/search_payment_by_container_number',
                {
                    search: this.search,
                }
            ).then(response => {
                if(response.status == 200) {
                    this.payments = response.data;
                    // console.log(this.payments);
                }
            }).catch(error => {
                console.log(error);
            }).finally( () => {
                this.overlay = false;
                this.loading = false;                
            });
        },
        searchTransactionByContainerNumber: function() {
            this.overlay = true;
            this.loading = true;            
            axios.post(
                '/api/search_transaction_by_container_number',
                {
                    search: this.search,
                }
            ).then(response => {
                if(response.status == 200) {
                    this.transactions = response.data;
                    // console.log(this.transactions);
                }
            }).catch(error => {
                console.log(error);
            }).finally( () => {
                this.overlay = false;
                this.loading = false;                
            });
        },
        openUpdatePaymentDialog: function() {
            this.mode = 'update';
            this.currentId = null;
            this.currentContainerNumber = null;
            this.currentEmail = null;
            this.currentRefNo = null;
            this.currentTransactionIdPrefix = null;
            this.currentTransactionId = null;

            this.updatePaymentDialog = true;
            this.currentContainerNumber = this.search;
            // this.currentTransactionIdPrefix = this.payments[0].txnid.split('TXN')[0];
            this.currentId = this.payments[0].id;
            this.currentTransactionId = 'TXN' + this.payments[0].txnid.split('TXN')[1];
            this.currentEmail = this.payments[0].email
        },
        createPayment: function() {
            this.mode = 'create';
            this.currentContainerNumber = null;
            this.currentEmail = null;
            this.currentRefNo = null;
            this.currentTransactionIdPrefix = null;
            this.currentTransactionId = null;
            this.currentId = null;

            this.updatePaymentDialog = true;
            this.currentContainerNumber = this.search;
            this.currentTransactionId = this.transactions[0].transaction_id;
            this.currentEmail = this.transactions[0].email
        },
        updatePayment: function() {
            if (!this.$refs.form.validate()) {
                return false;
            }  
            
            this.overlay = true;
            this.loading = true;
            axios.post(
                '/api/update_payment',
                {
                    mode: this.mode,
                    id: this.currentId,
                    container_number: this.search,
                    email: this.currentEmail,
                    ref_no: this.currentRefNo,
                    transaction_id_prefix: this.currentTransactionIdPrefix,
                    transaction_id: this.currentTransactionId,
                }
            ).then(response => {
                if(response.status == 200) {
                    this.updatePaymentDialog = false;
                    // console.log(response.data);
                }
            }).catch(error => {
                console.log(error);
            }).finally( () => {
                this.overlay = false;
                this.loading = false;                
            });
        }
    },
    filters: {
      formatDate: function (date) {
          return date !== null ? moment(date).format("MM-DD-YYYY hh:mm:ss A") : '';
      },
  },    
}
</script>