require('./bootstrap');
import Vue from 'vue';
import Vuetify from 'vuetify';
import router from './router';
import store from './store';
import App from './App.vue';
import 'vuetify/dist/vuetify.min.css';
import VueAxios from 'vue-axios';
import VueFullscreen from 'vue-fullscreen';
import '@mdi/font/css/materialdesignicons.css'

axios.defaults.headers.common["Authorization"] =
    "Bearer " + store.state.access_token;

axios.defaults.headers.get['Accept'] = 'application/json';

Vue.use(Vuetify);
Vue.use(VueAxios, axios);
Vue.use(VueFullscreen);

new Vue({
  el: '#app',
  vuetify: new Vuetify(),
  router,
  store,
  components: { App },
  template: '<App/>'
});
