<template>
    <div>
        <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card flat>
            <v-container>
                <v-toolbar flat dark class="indigo lighten-3">
                    <v-toolbar-title> Picture Uploading Form </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                <canvas ref="canvas" style="display: none;"></canvas>


                <v-row>
                    <v-col cols="12">
                        <v-list>
                            <v-toolbar flat dark color="indigo lighten-3 my-2">
                                <v-toolbar-title>Picture(s) To Be Uploaded</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <!-- Floating Button start -->
                                <!-- <template v-slot:extension>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                            fab
                                            color="green"
                                            bottom
                                            right
                                            absolute
                                            small
                                            v-on="on"
                                            @click="changePictureSizeDialog = !changePictureSizeDialog"
                                            >
                                            <v-icon>mdi-resize</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Change Picture Size to be uploaded</span>
                                    </v-tooltip>
                                </template> -->
                                <!-- Floating Button end -->
                            </v-toolbar>
                            <v-list-item v-for="(a, i) in areas" :key="`file${i}`">
                                <v-list-item-content>
                                        <v-file-input
                                            dense
                                            outlined
                                            small-chips
                                            multiple
                                            v-model="userFiles[i]"
                                            prepend-icon="mdi-camera"
                                            :placeholder="`${a.area_type}-${a.area_name}`"
                                            capture="camera"
                                            accept="image/*"
                                            @change="addFiles(a, i)"
                                        >
                                        <template v-slot:append-outer>
                                            <v-icon
                                                color="red"
                                                @click="openViewThumbnailDialog(a)"
                                            >mdi-alert-circle</v-icon>
                                            <v-btn
                                                @click="skipUploading(a, i)"
                                                dark
                                                class="red lighten-3"
                                                v-show="a.skip"
                                            >
                                                <v-icon>mdi-close-octagon</v-icon>skip
                                            </v-btn>

                                        </template>
                                        </v-file-input>
                                        <v-progress-linear
                                            height="15"
                                            :value="progressLinears[i]"
                                            dark
                                            stream
                                            class="my-2"
                                        ></v-progress-linear>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-list>
                            <v-toolbar flat dark color="indigo lighten-3 my-2">
                                <v-toolbar-title>Picture(s) Uploaded</v-toolbar-title>
                            </v-toolbar>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-data-table
                                        :headers="headers"
                                        :items="pictures"
                                        :items-per-page="-1"
                                        :loading="loading"
                                    >
                                        <template v-slot:[`item.actions`]="{ item }">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon
                                                        v-if="item.status === 'Pending'"
                                                        color="yellow"
                                                        v-on="on"
                                                    >mdi-pause</v-icon>
                                                </template>
                                                <span>Pending Picture</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon
                                                        color="green"
                                                        @click="viewPicture(item)"
                                                        v-on="on"
                                                        v-show="item.skipped != 1"
                                                    >mdi-eye</v-icon>
                                                </template>
                                                <span>View Picture</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon
                                                        color="red"
                                                        @click="deletePicture(item)"
                                                        v-on="on"
                                                        :disabled="disableSubmitPictures"
                                                        v-show="item.skipped != 1"
                                                    >mdi-delete</v-icon>
                                                </template>
                                                <span>Delete Picture</span>
                                            </v-tooltip>
                                        </template>
                                    </v-data-table>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        v-if="areas.length === 0"
                        color="green"
                        block
                        dark
                        @click="submitPictures"
                        :disabled="disableSubmitPictures"
                    >
                        <v-icon>mdi-image-move</v-icon>submit pictures
                    </v-btn>
                </v-card-actions>
            </v-container>
        </v-card>
        <!--changePictureSizeDialog start-->
        <v-dialog v-model="changePictureSizeDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Change Picture Size</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="changePictureSizeDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        Image Width:
                        <v-btn-toggle
                            v-model="imageWidthCond"
                            label="ImageWidth"
                            mandatory
                            light
                            dense
                        >
                            <v-btn>400px</v-btn>
                            <v-btn>600px</v-btn>
                            <v-btn>800px</v-btn>
                            <v-btn>OriginalSize</v-btn>
                        </v-btn-toggle>
                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
        <!--changePictureSizeDialog finish-->
        <!--viewThumbnailDialog start-->
        <v-dialog v-model="viewThumbnailDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title>
                            {{currentThumbnail.area_type + '-' + currentThumbnail.area_name}}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="viewThumbnailDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-subtitle>{{currentThumbnail.remark}}</v-card-subtitle>
                    <v-card-text>
                        <v-img :src="`/thumbnails/${currentThumbnail.thumbnail}`"></v-img>
                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
        <!--viewThumbnailDialog finish-->
    </div>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            loading: false,
            areas: [],
            userFiles: [],
            progressLinears: [],
            file_id: null,
            formData: null,
            headers: [
                {
                    text: "ContainerNumber",
                    value: "container_number",
                    sortable: true,
                },
                {
                    text: "TransactionId",
                    value: "transaction_id",
                    sortable: true,
                },
                {
                    text: "AreaType",
                    value: "area_type",
                    sortable: true,
                },
                {
                    text: "AreaName",
                    value: "area_name",
                    sortable: true,
                },
                {
                    text: "FileName",
                    value: "file_name",
                    sortable: true,
                },
                {
                    text: "Comment",
                    value: "comment",
                    sortable: true,
                },
                {
                    text: "Skipped",
                    value: "skipped",
                    sortable: true,
                },
                {
                    text: "Actions",
                    value: "actions",
                    sortable: false,
                },
            ],
            pictures: [],
            containerNumber: null,
            containerSizeId: null,
            containerTypeId: null,
            transactionId: null,
            email: null,
            imageWidthCond: 0,
            changePictureSizeDialog: false,
            disableSubmitPictures: false,

            viewThumbnailDialog: false,
            currentThumbnail: {},
        }
    },
    mounted() {
        this.containerNumber = this.$route.params.container_number.toUpperCase();
        this.transactionId = this.$route.params.transaction_id.toUpperCase();
        this.email = this.$route.params.email;

        this.axios.post(
            '/api/get_container',
            {
                container_number: this.containerNumber
            }
        ).then(response => {
            // console.log(response.data);
            this.containerSizeId = response.data[0].container_size_id;
            this.containerTypeId = response.data[0].container_type_id;
            this.loadAreas();
        }).catch(error => {
            console.log(error);
        });


        this.overlay = true;
        this.axios.post(
        '/api/get_payment',
        {
            transaction_id: this.$route.params.transaction_id,
            container_number: this.$route.params.container_number,
        }
        ).then(response => {
        // console.log(response.data);
        if(response.status === 200) {
            if(!response.data.length) {
            location.href = `/payment/${this.$route.params.container_number}/${this.$route.params.transaction_id}/${this.$route.params.email}`;
            }
        }
        }).catch(error => {
        console.log(error);
        }).finally(() => {
        this.overlay = false;
        });

        this.getTransaction();
        this.loadPictures();
    },
    methods: {
        getTransaction: function() {
            this.overlay = true;
            this.axios.post(
                '/api/get_transaction',
                {
                    transaction_id: this.transactionId,
                }
            ).then(response => {
                if(response.status === 200) {
                    if(response.data[0].email_sent_at) {
                        this.disableSubmitPictures = true;
                    } else {
                        this.disableSubmitPictures = false;
                    }
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        loadAreas: function() {
            this.overlay = true;
            this.axios.post(
                '/api/get_areas',
                {
                    container_number: this.containerNumber,
                    transaction_id: this.transactionId,
                    container_size_id: this.containerSizeId,
                    container_type_id: this.containerTypeId,
                }
            ).then(response => {
                // console.log(response.data);
                this.areas = response.data;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },

        loadPictures: function() {
            this.overlay = true;
            this.loading = true;

            this.axios.post(
            '/api/get_pictures_by_transaction_id',
            {
                transaction_id: this.$route.params.transaction_id
            }
        ).then(response => {
            this.pictures = response.data;
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            this.overlay = false;
            this.loading = false;
        });
        },
        addFiles: function (a, i) {

            this.file_id = i;
            this.formData = new FormData();

            if( typeof this.userFiles[i] === 'undefined' ) {
                    return false;
                }

            for(var file of this.userFiles[i]) {
                if( typeof file === 'undefined' ) {
                    return false;
                }
                if (!file.name.match(/png|jpg$/i)) {
                    const payload = {
                        title: "Invalid file chosen..",
                        msg: "You tried to upload invalid type of file...you can upload png / jpg only..",
                    };
                    this.$store.commit("showAlert", payload);
                    return false;
                } else {

                    const canvas = this.$refs.canvas;
                    const ctx = canvas.getContext('2d');
                    const img = new Image();
                    const self = this;
                    let NEW_SIZE = 0;

                    img.onload = function() {
                        switch(self.imageWidthCond) {
                            case 0:
                                NEW_SIZE = 800;
                                break;
                            case 1:
                                NEW_SIZE = 600;
                                break;
                            case 2:
                                NEW_SIZE = 400;
                                break;
                            case 3:
                                NEW_SIZE = img.naturalWidth;
                                break;
                        }
                        const aspectRatio = img.naturalHeight / img.naturalWidth;
                        canvas.width = NEW_SIZE;
                        canvas.height = NEW_SIZE * aspectRatio;
                        ctx.drawImage(img, 0, 0, NEW_SIZE, NEW_SIZE * aspectRatio);
                        const imageData = canvas.toDataURL('image/jpeg');
                        imageData.replace('data:image/jpeg;base64,', '');
                        let blobToFile = self.dataURItoBlob(imageData);
                        const cfile = new File([blobToFile], "test.jpeg",
                            {
                                type: "image/jpeg",
                                lastModified: Date.now()
                            }
                        );
                        self.formData.append('file', cfile);
                        self.formData.append("user_id", self.$store.state.user.id);
                        self.formData.append('container_number', self.containerNumber);
                        self.formData.append('transaction_id', self.transactionId);
                        self.formData.append('area_code', a.area_code);
                        self.formData.append('area_type', a.area_type);
                        self.formData.append('area_name', a.area_name);

                        self.overlay = true;
                        self.loading = true;

                        self.axios
                            .post(
                                "/api/save_file",
                                self.formData,
                                {
                                    onUploadProgress: self.onUpload,
                                },
                                {
                                    headers: {
                                    "Content-Type": "multipart/form-data",
                                    },
                                }
                            )
                            .then((response) => {
                            if (response.status === 200) {
                                self.areas.splice(self.areas.indexOf(a), 1);
                                self.userFiles.splice(i, 1);
                                self.progressLinears.splice(i, 1);

                                self.loadPictures();
                            }
                        }).catch(error => {
                            console.log(error);
                        }).finally(() => {
                            self.overlay = false;
                            self.loading = false;
                        });
                    };
                    img.src = URL.createObjectURL(file);
                    // this.formData.append("file", file);
                 }
            }
        },
        dataURItoBlob: function(dataURI) {
            var byteString = atob(dataURI.split(',')[1]);
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            var arrayBuffer = new ArrayBuffer(byteString.length);
            var _ia = new Uint8Array(arrayBuffer);
            for (var i = 0; i < byteString.length; i++) {
                _ia[i] = byteString.charCodeAt(i);
            }
            var dataView = new DataView(arrayBuffer);
            var blob = new Blob([dataView], {type: mimeString});
            return blob;
        },
        onUpload: function (e) {
            this.$set(
                this.progressLinears,
                this.file_id,
                Math.floor((e.loaded / e.total) * 100)
            );
        },

        deletePicture: function(item) {
            if(!confirm('Is it ok to delete the picture?')) {
                return false;
            }
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/delete_picture',
                {
                    id: item.id,
                    container_number: item.container_number,
                    transaction_id: item.transaction_id,
                    file_name: item.file_name,
                }
            ).then(response => {
                // console.log(response.data);
                if(response.status === 200) {
                    const payload = {
                        title: "Picture deleted",
                        msg: "The picture data has been successfully deleted...",
                    };
                    this.$store.commit("showAlert", payload);
                    this.loadAreas();
                    this.loadPictures();
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },

        viewPicture: function(item) {
            this.overlay = true;
            this.downloading = true;
            axios
                .post(
                    "/api/view_picture",
                    {
                        transaction_id: item.transaction_id,
                        container_number: item.container_number,
                        file_name: item.file_name,
                    },
                    {
                        responseType: "blob",
                    }
                )
                .then(response => {
                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data], { type: "image/jpeg" })
                    );
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.target = "_blank";
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.downloading = false;
                    this.overlay = false;
                });
        },

        submitPictures: function() {

            this.overlay = true;
            this.loading = true;

            this.axios.post(
                '/api/submit_pictures',
                {
                    transaction_id: this.$route.params.transaction_id,
                    container_number: this.$route.params.container_number,
                    user_id: this.$store.state.user.id,
                }
            ).then(response => {
                if(response.status === 200) {
                    const payload = {
                        title: "Pictures Submitted!",
                        msg: "The pictures have been successfully submitted...",
                    };
                    this.$store.commit("showAlert", payload);
                    this.disableSubmitPictures = true;
                    // location.href = '/transactions/' +
                    //     this.$route.params.container_number +
                    //     '.' +
                    //     this.$route.params.transaction_id;
                    // console.log(response.data);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        skipUploading: function(a, i) {

            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/skip_picture',
                {
                    container_number: this.containerNumber,
                    transaction_id: this.transactionId,
                    user_id: this.$store.state.user.id,
                    area_code: a.area_code,
                    area_type: a.area_type,
                    area_name: a.area_name,
                }
            ).then(response => {
                if(response.status === 200) {
                    this.areas.splice(this.areas.indexOf(a), 1);
                    this.userFiles.splice(i, 1);
                    this.loadPictures();
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },

        openViewThumbnailDialog: function(area) {
            this.viewThumbnailDialog = true;
            this.currentThumbnail = area;
        }
    }
}
</script>
