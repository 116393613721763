<template>
    <v-container>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <v-toolbar flat dark class="indigo lighten-3">
                <!-- <v-toolbar-title>Transactions *For Non-Pre-Advise Flow</v-toolbar-title> -->
                <v-toolbar-title>Transactions *For SITC</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="transactions"
                    :loading="loading"
                    :items-per-page="itemsPerPage"
                    fixed-header
                    height="50vh"
                    hide-default-footer
                >
                    <template v-slot:top>
                        <v-tabs
                            v-model="tab"
                            center-active
                            dark
                        >
                            <v-tabs-slider color="yellow"></v-tabs-slider>
                            <v-tab
                                v-for="(status, i) in statuses"
                                :key="i"
                            >
                            {{status.status_name}}
                        </v-tab>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-on="on"
                                    @click="generateCSV"
                                >
                                    <v-icon>mdi-file-delimited</v-icon>
                                </v-btn>
                            </template>
                            <span>
                                Generate CSV
                            </span>
                        </v-tooltip>
                        </v-tabs>
                        <!--Data Picker start-->
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-if="$store.state.user.role == 'su' || $store.state.user.role == 'surveyor' || $store.state.user.role == 'admin'"
                                    v-model="dateRangeText"
                                    label="Date Rnage"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="dates = []"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="dates"
                                range
                            ></v-date-picker>
                        </v-menu>
                        <!--Data Picker finish-->
                        <v-text-field
                            class="my-2"
                            prepend-inner-icon="mdi-magnify"
                            v-model="search"
                            outlined
                            placeholder="Search by transactionId, container# or destinationId"
                            dense
                            @keyup="searchByKeyword()"
                        ></v-text-field>
                        <!-- <p>*D-01: MICP | D-02: SOUTH | D-03: SUBIC | D-04: BILT</p> -->
                        # of records: <span style="color: red; font-weight: bold;">{{totalNumberOfRecords}}</span>
                    </template>
                    <template v-slot:[`item.transaction_id`]="{ item }">
                        <v-icon color="red" v-show="item.viewed_at == null && tab == 2">mdi-new-box</v-icon>
                        {{item.transaction_id}}
                    </template>
                    <template v-slot:[`item.last_surveyor`]="{ item }">
                        {{ lastSurveyor(item.pictures) }}
                    </template>
                    <template v-slot:[`item.progress`]="{ item }">
                        <v-badge
                            color="red"
                            :content="numberOfPictures(item, 'Not yet started') ? numberOfPictures(item, 'Not yet started') : '0'"
                            overlap
                            bordered
                            v-show="tab != 2"
                        >
                        <v-chip color="red" dark x-small>Not yet started</v-chip>
                        </v-badge>
                        <v-badge
                            color="red"
                            :content="numberOfPictures(item, 'Pending') ? numberOfPictures(item, 'Pending') : '0'"
                            overlap
                            bordered
                            v-show="tab != 2"
                        >
                        <v-chip color="orange" dark x-small>Pending</v-chip>
                        </v-badge>
                        <v-badge
                            color="red"
                            :content="numberOfPictures(item, 'Finished') ? numberOfPictures(item, 'Finished') : '0'"
                            overlap
                            bordered
                        >
                        <v-chip color="green" dark x-small>Finished</v-chip>
                        </v-badge>
                    </template>
                    <template v-slot:[`item.pictures`]="{ item }">
                        <v-badge
                            v-if="item.pictures.length > 0 && item.email_sent_at != null"
                            bordered
                            color="red"
                            :content="item.pictures.length"
                            overlap
                        >
                            <v-icon
                                v-if="item.pictures.length > 0 && item.email_sent_at != null"
                                color="green"
                                large
                                @click="openImagesDialog(item, tab)"
                                :disabled="($store.state.user.role === 'admin' || $store.state.user.role === 'user') && tab === 1"
                            >mdi-image</v-icon>
                        </v-badge>
                    </template>
                    <template v-slot:[`item.download_zip`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    v-if="item.pictures.length > 0 && item.email_sent_at != null"
                                    color="blue"
                                    large
                                    v-on="on"
                                    @click="downloadZip(item)"
                                    :disabled="($store.state.user.role === 'admin' || $store.state.user.role === 'user') && tab === 1"
                                >
                                    mdi-folder-zip
                                </v-icon>
                            </template>
                            <span>Download ZIP</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.created_at`]="{ item }">
                        {{ item.created_at | formatDate }}
                    </template>
                    <template v-slot:[`item.created`]="{ item }">
                        {{ item.user.name }}({{ item.company.name }})
                    </template>
                    <template v-slot:[`item.updated_by`]="{ item }">
                        <div v-if="tab === 2">{{item.updated_by}}</div>
                    </template>
                    <template v-slot:[`item.updated_at`]="{ item }">
                        <div v-if="tab === 2">{{ item.updated_at == item.created_at ? null : item.updated_at | formatDate }}</div>
                    </template>
                    <template v-slot:[`item.link`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{on}">
                                <v-btn
                                    v-if="item.email_sent_at == null && ($store.state.user.role.match(/su/) || $store.state.user.role.match(/user/))"
                                    icon
                                    :to="`/otp_input/${item.container_number}/${item.transaction_id}/${item.email}`"
                                    color="green"
                                    v-on="on"
                                >
                                    <v-icon>link</v-icon>
                                </v-btn>
                            </template>
                            <span>Go to container picture taking page</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.pin`]="{ item }">
                        <p v-if="item.email_sent_at == null && $store.state.user.role.match(/su/)">{{ item.pin }}</p>
                    </template>
                </v-data-table>
                <div class="text-center pt-2">
                    <v-pagination
                        v-model="page"
                        :length="pageCount"
                        total-visible="10"
                        dark
                        @input="changePage()"
                    ></v-pagination>
                </div>
            </v-card-text>
        </v-card>
        <!-- imagesDialog start -->
        <v-dialog v-model="imagesDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Pictures -- {{currentContainerNumber}} --</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="imagesDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-btn
                            block
                            color="green"
                            class="lighten-2"
                            dark
                            v-if="showForwardPictureButton"
                            @click="forwardPicturesToShippingLine"
                        >
                        <v-icon>mdi-thumb-up</v-icon>
                        Send to Shipping Line</v-btn>
                        <v-btn
                            block
                            color="orange"
                            class="lighten-2"
                            dark
                            v-if="showForwardPendingPictureButton"
                            @click="forwardPendingPicturesToTrucker"
                        >
                        <v-icon>mdi-thumb-down</v-icon>
                        Send To Trucker
                        </v-btn>
                        <v-data-table
                            :headers="pictureHeaders"
                            :items="pictures"
                            :items-per-page="-1"
                        >
                            <template v-slot:[`item.status`]="{ item }">
                                <v-icon
                                    :color="statusIcons[item.status].color"
                                    large
                                    @click="openUpdateStatusDialog(item)"
                                >
                                    {{statusIcons[item.status].icon}}
                                </v-icon>
                                <v-radio-group
                                    v-model="item.status"
                                    @change="updateStatus2(item)"
                                    v-if="$store.state.user.role.match(/su/) && tab == 1"
                                >
                                    <v-radio
                                        label="Not yet started"
                                        value="Not yet started"
                                    ></v-radio>
                                    <v-radio
                                        label="Pending"
                                        value="Pending"
                                    ></v-radio>
                                    <v-radio
                                        label="Finished"
                                        value="Finished"
                                    ></v-radio>
                                </v-radio-group>

                            </template>
                            <template v-slot:[`item.comment`]="{ item }">
                                <v-text-field
                                    dense
                                    outlined
                                    label="Comment"
                                    v-if="item.status !== 'Not yet started' && $store.state.user.role != 'admin' && tab == 1"
                                    v-model="item.comment"
                                    @blur="updateComment2(item)"
                                ></v-text-field>
                                <v-text-field
                                    dense
                                    outlined
                                    label="Comment"
                                    v-else
                                    v-model="item.comment"
                                    readonly
                                ></v-text-field>

                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            color="green"
                                            @click="downloadPicture(item)"
                                            large
                                            v-on="on"
                                        >mdi-image</v-icon>
                                    </template>
                                    <span>View Picture</span>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at | formatDate }}
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
        <!--imagesDialog end -->
        <!-- updateStatusDialog start -->
        <v-dialog v-model="updateStatusDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Update Status</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="updateStatusDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-menu :disabled="disableStatusSelection">
                            <template v-slot:activator="{ on }">
                                Status: <v-icon
                                    :color="statusIcons[currentPictureInfo.status].color"
                                    large
                                    v-on="on"
                                >
                                    {{statusIcons[currentPictureInfo.status].icon}}
                                </v-icon>
                            </template>
                            <v-list>
                                <v-list-item
                                    v-for="(s, i) in statusIcons"
                                    :key="i"
                                    link
                                    @click="updateStatus(i)"
                                >
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                :color="s.color"
                                                v-on="on"
                                            >
                                                {{s.icon}}
                                            </v-icon>
                                        </template>
                                        <span>{{i}}</span>
                                    </v-tooltip>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-textarea
                            dense
                            outlined
                            v-model="comment"
                            label="Comment"
                            placeholder="Comment"
                            :disabled="disableCommentTextarea"
                            @blur="updateComment"
                        ></v-textarea>
                    </v-card-text>
                    <!-- <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            dark
                            color="red"
                            class="lighten-2"
                            block
                            @click="saveComment"
                        >
                            <v-icon>mdi-content-save</v-icon>
                            Save
                        </v-btn>
                    </v-card-actions> -->
                </v-container>
            </v-card>
        </v-dialog>
        <!--updateStatusDialog finish-->
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            users: [],
            tab: 0,
            search: null,
            overlay: false,
            loading: false,
            pageCount: 0,
            page: 1,
            itemsPerPage: 30,
            totalNumberOfRecords: null,
            imagesDialog: false,
            headers: [
                {
                    text: "transactionId",
                    value: "transaction_id",
                    sortable: false,
                },
                {
                    text: "container#",
                    value: "container_number",
                    sortable: false,
                },
                {
                    text: "destinationId",
                    value: "destination.destination_type",
                    sortable: false,
                },
                {
                    text: "createdBy",
                    value: "created",
                    sortable: false,
                },
                {
                    text: "createdAt",
                    value: "created_at",
                    sortable: false,
                },
                {
                    text: "updatedBy",
                    value: "updated_by.email",
                    sortable: false,
                },
                {
                    text: "updatedAt",
                    value: "updated_at",
                    sortable: false,
                },
                {
                    text: "last_surveyor",
                    value: "last_surveyor",
                    sortable: false,
                },
                {
                    text: "progress",
                    value: "progress",
                    sortable: false,
                },
                {
                    text: "pictures",
                    value: "pictures",
                    sortable: false,
                },
                {
                    text: "download ZIP",
                    value: "download_zip",
                    sortable: false,
                },
                {
                    text: "Link",
                    value: "link",
                    sortable: false,
                },
                {
                    text: "OTP",
                    value: "pin",
                    sortable: false,
                },
            ],
            transactions: [],
            currentItem: null,
            currentContainerNumber: null,
            pictureHeaders: [
                {
                    text: "FileName",
                    value: "file_name",
                    sortable: true,
                },
                {
                    text: "Status",
                    value: "status",
                    sortable: true,
                },
                {
                    text: "Comment",
                    value: "comment",
                    sortable: true,
                },
                {
                    text: "CreatedAt",
                    value: "created_at",
                    sortable: true,
                },
                {
                    text: "Actions",
                    value: "actions",
                    sortable: true,
                }
            ],
            pictures: [],
            statuses: [],
            statusIcons: {
                'Not yet started': {
                    icon: 'mdi-stop',
                    color: 'red'
                },
                'Pending': {
                    icon: 'mdi-pause',
                    color: 'yellow'
                },
                'Finished': {
                    icon: 'mdi-check',
                    color: 'green'
                }
            },
            showForwardPictureButton: false,
            showForwardPendingPictureButton: false,
            disableStatusSelection: false,
            updateStatusDialog: false,
            comment: null,
            currentPictureInfo: null,
            disableCommentTextarea: true,
            menu: false,
            dates: [
                `${moment().format('YYYY')}-${moment().format('MM')}-01`,
                `${moment().format('YYYY')}-${moment().format('MM')}-${moment().daysInMonth()}`
            ]
        }
    },
    mounted() {
        this.getIpAddress();
        if( this.$store.state.user.id === 3 ) {
            // Push.create('ttt');
        }
        this.loadTransactions(0);
        this.getOriginalStatuses();
        this.axios.post(
                '/api/get_users'
            ).then(response => {
                if(response.status === 200) {
                    this.users = response.data;
                }
            }).catch(error => {
                console.log(error);
            });

    },
    methods: {
        openUpdateStatusDialog: function(item) {
            if(this.currentItem.status_id === 'S-04') {
                return false;
            }
            this.comment = item.comment;
            this.currentPictureInfo = item;
            this.updateStatusDialog = true;
            // console.log(item);
        },
        updateStatus: function(i) {
            if(i !== 'Not yet started') {
                this.disableCommentTextarea = false;
            } else {
                this.disableCommentTextarea = true;
            }

            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/update_status',
                {
                    id: this.currentPictureInfo.id,
                    user_id: this.$store.state.user.id,
                    status: i,
                }
            ).then(response => {
                if(response.status === 200) {
                    const index = this.pictures.indexOf(this.currentPictureInfo);
                    this.$set(this.pictures[index], 'status', i);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        updateStatus2: function(item) {
            // console.log(item)
            // return false;
            if(item.status !== 'Not yet started') {
                this.disableCommentTextarea = false;
            } else {
                this.disableCommentTextarea = true;
            }

            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/update_status',
                {
                    id: item.id,
                    user_id: this.$store.state.user.id,
                    status: item.status,
                }
            ).then(response => {
                if(response.status === 200) {
                    const index = this.pictures.indexOf(item);
                    this.$set(this.pictures[index], 'status', item.status);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        updateComment: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/update_comment',
                {
                    id: this.currentPictureInfo.id,
                    user_id: this.$store.state.user.id,
                    comment: this.comment,
                }
            ).then(response => {
                if(response.status === 200) {
                    const index = this.pictures.indexOf(this.currentPictureInfo);
                    this.$set(this.pictures[index], 'comment', this.comment);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        updateComment2: function(item) {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/update_comment',
                {
                    id: item.id,
                    user_id: this.$store.state.user.id,
                    comment: item.comment,
                }
            ).then(response => {
                if(response.status === 200) {
                    const index = this.pictures.indexOf(item);
                    this.$set(this.pictures[index], 'comment', item.comment);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        numberOfPictures: function(item, status) {
            const obj = item.pictures.filter(value => {
                return value.status === status;
            });
            return obj.length;
        },
        loadTransactions: function(status) {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/get_transactions_for_original_flow',
                {
                    user: this.$store.state.user,
                    status: status,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage,
                    search: this.search,
                    dates: this.dates,
                    company_id: 'CLI-0002'
                }
            ).then(response => {
                // console.log(response.data.data);
                if(response.status === 200) {
                    this.transactions = response.data.data;
                    this.totalNumberOfRecords = response.data.total;
                    this.pageCount = Math.ceil(response.data.total / this.itemsPerPage);
                }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        },
        getOriginalStatuses: function() {
            this.axios.post('/api/get_original_statuses')
                .then(response => {
                    this.statuses = response.data;
                    // console.log(response.data)
                }).catch(error => {
                    console.log(error);
                });
        },
        openImagesDialog: function(item, tab) {
            this.imagesDialog = true;
            this.currentItem = item;
            this.currentContainerNumber = this.currentItem.container_number;
            this.pictures = this.currentItem.pictures;

            if(tab === 2) {
                this.axios.post(
                    '/api/update_viewed_at',
                    {
                        transaction_id: item.transaction_id
                    }
                ).then(response => {
                    if(response.status === 200) {
                        // console.log(response.data);
                        this.loadTransactions(2);
                    }
                }).catch(error => {
                    console.log(error);
                });
            }
        },
        downloadPicture: function (item) {
        this.overlay = true;
        axios
            .post(
            "/api/download_picture",
            {
                container_number: item.container_number,
                transaction_id: item.transaction_id,
                name: item.file_name,
            },
            {
                responseType: "blob",
            }
            )
            .then((response) => {
            this.overlay = false;
            const fileURL = window.URL.createObjectURL(
                new Blob([response.data], { type: "image/jpg" })
            );
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.target = "_blank";
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
            });
        },
        forwardPicturesToShippingLine: function() {
            if(!confirm('Is it ok to send the information to Shipping Line?')) {
                return false;
            }
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/forward_pictures_to_shipping_line_for_original_flow',
                {
                    transaction_id: this.currentItem.transaction_id,
                    container_number: this.currentItem.container_number,
                    user_id: this.$store.state.user.id,
                }
            ).then(response => {
                if(response.status === 200) {
                    // console.log(response.data);
                    this.imagesDialog = false;
                    this.tab = 2;
                    this.showForwardPictureButton = false;
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        forwardPendingPicturesToTrucker: function() {
            if(!confirm('Is it ok to send the information to Trucker?')) {
                return false;
            }
            const pendingPics = this.currentItem.pictures.filter(value => {
                return value.status === 'Pending';
            });

            this.axios.post(
                '/api/forward_pending_pictures_to_trucker_for_original_flow',
                {
                    transaction_id: this.currentItem.transaction_id,
                    pendingPics: pendingPics,
                }
            ).then(response => {
                // console.log(response.data);
                if(response.status === 200) {
                    this.imagesDialog = false;
                    this.tab = 0
                    this.loadTransactions(0);
                    this.showForwardPendingPictureButton = false;
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        downloadZip: function(item) {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/download_zip',
                {
                    container_number: item.container_number,
                    transaction_id: item.transaction_id,
                    name: 'Exterior-Exterior Right Side Panel.png'
                },
                {
                    responseType: "blob",
                }
            ).then(response => {
                if(response.status === 200) {
                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/zip" })
                    );
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.target = "_blank";
                    fileLink.download = item.container_number + '.zip';
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        changePage: function() {
            this.loadTransactions(this.tab);
        },
        searchByKeyword: function() {
            this.page = 1;
            this.loadTransactions(this.tab);
        },
        lastSurveyor: function(obj) {
            var id = 0;
            for(var o of obj) {
                if(o.updated_by) {
                    id = o.updated_by;
                }
            }

            var last_surveyor = '';
            for(var user of this.users) {
                if(user.id == id) {
                    last_surveyor = user.name;
                }
            }
            return last_surveyor;
        },
        generateCSV: function() {
            this.downloading = true;
            this.overlay = true;
            this.axios.post(
                '/api/generate_transactions_csv',
                {
                    tab: this.tab,
                    dates: this.dates,
                    process: 'sitc',
                },
                {
                    responseType: "blob",
                },
            )
                .then(response => {
                    // console.log(response.data)
                    this.downloading = false;
                    this.overlay = false;
                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data], { type: "text/csv" })
                    );
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", "transactions.csv");
                    fileLink.target = "_blank";
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                }).catch(error => {
                    console.log(error);
                });
        },
        getIpAddress: function() {
            axios.post(
                '/api/get_ip_address',
                {
                    email: this.$store.state.user.email,
                }
            ).then(response => {
                if( response.status === 200 ) {
                    // console.log(response.data);
                }
            }).catch(error => {
                console.log(error);
            });
        },
    },
    filters: {
        formatDate: function (date) {
            return date !== null ? moment(date).format("MM-DD-YYYY hh:mm:ss A") : '';
        }
    },
    computed: {
        dateRangeText() {
            return this.dates.join(' ~ ');
        }
    },
    watch: {
        tab: function() {
            this.page = 1;
            this.loadTransactions(this.tab);
        },
        pictures: {
            handler() {
                if(this.currentItem.submitted_at) {
                    this.disableStatusSelection = true;
                    return false;
                }

                if(
                    this.$store.state.user.role === 'su'
                    ||
                    this.$store.state.user.role === 'surveyor'
                ) {
                    this.disableStatusSelection = false;
                } else {
                    this.disableStatusSelection = true;
                }


                const notYetStarted = this.pictures.filter((value) => {
                    return value.status === 'Not yet started';
                });
                const pending = this.pictures.filter((value) => {
                    return value.status === 'Pending';
                });

                if(
                    this.$store.state.user.role === 'su'
                    ||
                    this.$store.state.user.role === 'surveyor'
                ) {
                    if(notYetStarted.length == 0 ) {
                        if(pending.length > 0) {
                            this.showForwardPictureButton = false;
                            this.showForwardPendingPictureButton = true;
                        } else {
                            this.showForwardPictureButton = true;
                            this.showForwardPendingPictureButton = false;
                        }
                    } else {
                        this.showForwardPictureButton = false;
                        this.showForwardPendingPictureButton = false;
                    }
                }
            },
            deep: true
        },
        dates: function() {
            if (this.dates.length == 0) {
                return false;
            } else if (this.dates.length == 2) {
                if ( this.dates[0] > this.dates[1] ) {
                    alert('Invalid date selection');
                    this.dates = [];
                    return false;
                }
            }

            this.page = 1;
            this.loadTransactions(this.tab);
        }
    }

}
</script>
<style lang="css">
.pagination {
}
.page-item {
}
</style>
