<template>
    <v-app-bar
        app
        dark
        class="indigo lighten-2"
        clipped-left
    >
        <v-app-bar-nav-icon
            @click="$store.commit('toggleDrawer')"
            v-if="$store.state.isLogged"
        ></v-app-bar-nav-icon>
        <v-toolbar-title>{{ appName }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">

                <div
                    v-if="$store.state.notificationsCount === 0"
                >
                    <v-icon
                        @click="openNotifications"
                        v-on="on"
                        class="mx-3"
                    >mdi-bell</v-icon>
                </div>
                <div
                    v-else
                >
                    <v-badge
                        color="error"
                        :content="$store.state.notificationsCount"
                        offset-x="20"
                        offset-y="15"
                    >
                        <v-icon
                            @click="openNotifications"
                            v-on="on"
                            class="mx-3"
                        >mdi-bell</v-icon>
                    </v-badge>
                </div>

            </template>
            <span>Notification</span>
        </v-tooltip> -->
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-icon
                    @click="openUserManual"
                    v-on="on"
                    class="mx-3"
                >mdi-help-circle</v-icon>
            </template>
            <span>User Manual</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-icon
                    @click="logout"
                    v-show="$store.state.isLogged"
                    v-on="on"
                >mdi-logout</v-icon>
            </template>
            <span>Logout</span>
        </v-tooltip>
    </v-app-bar>
</template>
<script>
export default {
    data() {
        return {
            appName: null,
        }
    },
    methods: {
        logout: function() {
            this.$store.commit('logOut');
            location.href = '/login';
        },
        openUserManual: function() {
            window.open('https://github.com/dev20220810/bright-light-ventures-ph-documentation/wiki', '_blank');
        },
        openNotifications: function()
        {
            location.href = '/notifications';
        }
    },
    created() {
        this.appName = process.env.MIX_APP_NAME;
        // this.axios.post('/api/get_notifications')
        //     .then(response => {
        //         if(response.status === 200) {
        //             this.$store.commit('updateNotificationsCount', response.data.length);
        //         }
        //     }).catch(error => {
        //         console.log(error);
        //     }).finally(() => {

        //     });
    }
}
</script>
