<template>
    <v-container>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title>Profile</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-form ref="form">
                    <v-card-text>
                        <v-text-field
                            outlined
                            dense
                            label="UserEmailAddress"
                            placeholder="UserEmailAddress"
                            v-model="userEmailAddress"
                            readonly
                        ></v-text-field>
                        <v-text-field
                            outlined
                            dense
                            label="UserName"
                            placeholder="UserName"
                            v-model="userName"
                            :rules="rules"
                        ></v-text-field>
                        <v-text-field
                            outlined
                            dense
                            label="UserCompanyName"
                            placeholder="UserCompanyName"
                            v-model="userCompanyName"
                            :rules="rules"
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            block
                            dark
                            color="red"
                            class="lighten-3"
                            @click="updateProfile()"
                        >
                            <v-icon>mdi-update</v-icon> Update
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            userName: null,
            userEmailAddress: null,
            userCompanyName: null,
            rules: [(v) => !!v || "Required"],
        }
    },
    mounted() {
        this.userEmailAddress = this.$store.state.user.email;
        this.userName = this.$store.state.user.name;
        this.userCompanyName = this.$store.state.user.organization;
        this.getIpAddress();
        // console.log(this.$store.state.user.company_id)
    },
    methods: {
        getIpAddress: function() {
            axios.post(
                '/api/get_ip_address',
                {
                    email: this.$store.state.user.email,
                }
            ).then(response => {
                if( response.status === 200 ) {
                    // console.log(response.data);
                }
            }).catch(error => {
                console.log(error);
            });
        },
        updateProfile: function() {
            if (!this.$refs.form.validate()) {
                return false;
            }

            this.overlay = true;
            axios.post(
                '/api/update_profile',
                {
                    email: this.userEmailAddress,
                    name: this.userName,
                    organization: this.userCompanyName,
                }
            ).then(response => {
                if(response.status === 200) {
                    const payload = {
                        title: "User Profile Updated!",
                        msg: "Automatically log-out...please log-in again so that the new user profile will be properly enhanced...",
                    };
                    this.$store.commit("showAlert", payload);
                    this.$store.commit('logOut');
                    location.href = '/login';
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        }
    }
}
</script>
