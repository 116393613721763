<template>
  <v-container>
      <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card class="mx-auto px-2 pt-2">
          <v-toolbar flat dark class="indigo lighten-3">
              <v-toolbar-title>Users</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
              <v-data-table
                  :headers="headers"
                  :items="users"
                  :loading="loading"
                  :search="search"
                  :items-per-page="30"
                  fixed-header
                  height="50vh"
                  hide-default-footer
                  :page.sync="page"
                  @page-count="pageCount = $event"
              >
                  <template v-slot:top>
                      <v-text-field
                          class="my-2"
                          prepend-inner-icon="mdi-magnify"
                          v-model="search"
                          outlined
                          dense
                      ></v-text-field>
                      # of records: <span style="color: red; font-weight: bold;">{{users.length}}</span>
                  </template>
                  <template v-slot:[`item.created_at`]="{ item }">
                    {{ item.created_at | formatDate }}
                  </template>
                  <template v-slot:[`item.updated_at`]="{ item }">
                    {{ item.updated_at | formatDate }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          @click="editUser(item)"
                          color="orange"
                          v-on="on"
                        >mdi-pencil</v-icon>
                      </template>
                      <span>Edit User</span>
                    </v-tooltip>
                  </template>
              </v-data-table>
              <div class="text-center pt-2">
                  <v-pagination
                      v-model="page"
                      :length="pageCount"
                      total-visible="10"
                  ></v-pagination>
              </div>
          </v-card-text>
      </v-card>
        <!--editUserDialog start-->
        <v-dialog v-model="editUserDialog" fullscreen>
          <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card flat>
            <v-container>
              <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title
                >Edit User</v-toolbar-title
                >
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  dark
                  @click="editUserDialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-text-field
                  outlined
                  dense
                  label="Email"
                  v-model="email"
                  readonly
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="Name"
                  v-model="name"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="Organization"
                  v-model="organization"
                ></v-text-field>
                <v-select
                  outlined
                  dense
                  label="Role"
                  :items="roles"
                  v-model="role"
                ></v-select>
                <v-select
                  outlined
                  dense
                  label="CompanyName"
                  :items="companies"
                  v-model="company_id"
                  item-text="name"
                  item-value="company_id"
                  :disabled="role !== 'admin'"
                ></v-select>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  block
                  dark
                  color="red"
                  class="lighten-2"
                  @click="updateUser"
                >
                  <v-icon>mdi-content-save</v-icon>update
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-dialog>
        <!--editUserDialog finish-->
    </v-container>
</template>
<script>
export default {
  data() {
    return {
      overlay: false,
      headers: [
        {
          text: "Email",
          value: "email",
          sortable: true,
        },
        {
          text: "Name",
          value: "name",
          sortable: true,
        },
        {
          text: "Organization",
          value: "organization",
          sortable: true,
        },
        {
          text: "Role",
          value: "role",
          sortable: true,
        },
        {
          text: "CreatedAt",
          value: "created_at",
          sortable: true,
        },
        {
          text: "UpdatedAt",
          value: "updated_at",
          sortable: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: true,
        },
      ],
      users: [],
      loading: false,
      search: null,
      page: 1,
      pageCount: 30,
      editUserDialog: false,
      email: null,
      name: null,
      organization: null,
      roles: [
        'user',
        'surveyor',
        'admin',
        'su'
      ],
      role: null,
      companies: [],
      company_id: null,
    }
  },
  mounted() {

    this.overlay = true;
    this.loading = true;
    this.getUsers();
  },
  methods: {
    getUsers: function() {
      this.axios.post('/api/get_users')
      .then(response => {
        this.users = response.data;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.overlay = false;
        this.loading = false;
      });
    },
    editUser: function(item) {
      this.editUserDialog = true;
      this.email = item.email;
      this.name = item.name;
      this.organization = item.organization;
      this.role = item.role;
      this.company_id = item.company_id;

      this.axios.post('/api/get_companies')
        .then(response => {
            this.companies = response.data;
        }).catch(error => {
            console.log(error);
        });
    },
    updateUser: function() {
      if(this.role !== 'user') {
        if(!confirm('Is it ok to change the role?')) {
          return false;
        }
      }

      this.overlay = true;
      this.loading = true;

      this.axios.post(
        '/api/update_user',
        {
          email: this.email,
          name: this.name,
          organization: this.organization,
          role: this.role,
          company_id: this.company_id,
        }
      ).then(response => {
        if(response.status === 200) {
          this.editUserDialog = false;
          const payload = {
              title: "User Record Updated!",
              msg: "You have successfully updated the user record...",
          };
          this.$store.commit("showAlert", payload);
        }
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.overlay = false;
        this.loading = false;
        this.getUsers();
      });
    }
  },
  filters: {
      formatDate: function (date) {
          return date !== null ? moment(date).format("MM-DD-YYYY hh:mm:ss A") : '';
      },
  },
}
</script>
