<template>
    <div>
        <v-container>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card class="mx-auto px-2 pt-2">
                <v-toolbar flat dark class="indigo lighten-3">
                    <v-toolbar-title>Notifications</v-toolbar-title>
                </v-toolbar>

                <v-btn
                    block
                    color="green"
                    class="lighten-2 my-2"
                    dark
                    @click="sendNotificationToAllUsersDialog = true"
                >
                    <v-icon>mdi-message-text-fast</v-icon>
                    Send Notification
                </v-btn>
                <v-spacer></v-spacer>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    :loading="loading"
                >
                    <template v-slot:[`item.body`]="{item}">
                        <span v-html="nl2br(item.body)"></span>
                    </template>
                    <template v-slot:[`item.created_at`]="{item}">
                        {{ item.created_at | formatDate }}
                    </template>
                    <template v-slot:[`item.seen`]="{item}">
                        <span
                            v-if="item.seen === 1"
                        >
                            <v-icon
                                color="green"
                            >mdi-check</v-icon>
                        </span>
                        <span
                            v-else
                        >
                            <v-btn
                                dark
                                color="black"
                                small
                                @click="seenNotification(item)"
                            >
                            Got it!
                            </v-btn>
                        </span>
                    </template>
                </v-data-table>
            </v-card>
        </v-container>
        <!--sendNotificationToAllUsersDialog start-->
        <v-dialog v-model="sendNotificationToAllUsersDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Send Notification(s)</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="sendNotificationToAllUsersDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="form">
                            <v-text-field
                                v-model="title"
                                label="Title"
                                placeholder="Provide title of the message..."
                                dense
                                outlined
                                :rules="rules"
                            ></v-text-field>
                            <v-textarea
                                v-model="body"
                                label="Body"
                                placeholder="Write the TextBody..."
                                dense
                                outlined
                                :rules="rules"
                            ></v-textarea>
                            <v-text-field
                                v-model="url"
                                label="Link URL"
                                placeholder="Provide Link URL if needed..."
                                dense
                                outlined
                            ></v-text-field>
                            <v-switch
                                v-model="requireInteraction"
                                label="Notification dialog will not close unless the user manually closes"
                            >
                            </v-switch>
                            <v-btn
                                block
                                color="red"
                                class="lighten-2"
                                dark
                                @click="sendNotification"
                                :disable="disableSendNotificationButton"
                            >
                            <v-icon>mdi-send</v-icon>
                            Send Notification to all users
                            </v-btn>
                        </v-form>
                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
        <!--sendNotificationToAllUsersDialog finish-->
    </div>
</template>
<script>
import { assertExpressionStatement } from '@babel/types';
import io from 'socket.io-client';

export default {
    data() {
        return {
            headers: [
                {
                    text: "From",
                    value: "from",
                    sortable: true,
                },
                {
                    text: "To",
                    value: "to",
                    sortable: true,
                },
                {
                    text: "Title",
                    value: "title",
                    sortable: true,
                },
                {
                    text: "Body",
                    value: "body",
                    sortable: true,
                },
                {
                    text: "Link",
                    value: "link",
                    sortable: true,
                },
                {
                    text: "RequireInteraction",
                    value: "require_interaction",
                    sortable: true,
                },
                {
                    text: "CreatedAt",
                    value: "created_at",
                    sortable: true,
                },
                {
                    text: "Seen",
                    value: "seen",
                    sortable: true,
                },
            ],
            items: [],
            overlay: false,
            loading: false,
            sendNotificationToAllUsersDialog: false,
            disableSendNotificationButton: false,
            title: null,
            body: null,
            url: null,
            requireInteraction: false,
            rules: [(v) => !!v || "Required"],

            socket: null,
            wsApi: null,
            chatMsgs: ['sss', 'ttt'],

        }
    },
    created() {
        this.socket = io('http://localhost:3000', {
            transports: ['websocket']
        });
        this.getNotifications();
    },
    mounted() {
        // this.getNotifications();
        // this.socket.emit('chat message', 'ttt');

        this.socket.on('chat message', data => {
            this.chatMsgs = [...this.chatMsgs, data];
            this.sendNotificationFromSocketIoServer(data);
            Push.create(
                "Msg from socket.io server...",
                {
                    body: data,
                    link: '-',
                    requireInteraction: false,
                }
            );
        });
    },
    methods: {
        getNotifications: function() {
            this.axios.post('/api/get_notifications')
                .then(response => {
                    if(response.status === 200) {
                        this.items = response.data;
                        this.$store.commit('updateNotificationsCount', response.data.length);
                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {

                });
        },
        // getNotifications: function() {
        //     this.overlay = true;
        //     this.loading = true;
        //     this.axios.post('/api/get_notifications')
        //         .then(response => {
        //             if(response.status === 200) {
        //                 this.items = response.data;
        //                 this.$store.commit('updateNotificationsCount', response.data.length);
        //             }
        //         }).catch(error => {
        //             console.log(error);
        //         }).finally(() => {
        //             this.overlay = false;
        //             this.loading = false;
        //         });
        // },
        sendNotification: function() {
            if (!this.$refs.form.validate()) {
                return false;
            }

            this.overlay = true;
            this.disableSendNotificationButton = true;
            this.axios.post(
                '/api/send_notification',
                {
                    user_id: this.$store.state.user.id,
                    title: this.title,
                    body: this.body,
                    url: this.url,
                    requireInteraction: this.requireInteraction,
                }
            ).then(response => {
                if( response.status === 200 ) {
                    const payload = {
                        title: "Notification sent",
                        msg: "Notification has been sent successfully.",
                    };
                    this.$store.commit("showAlert", payload);

                    this.socket.emit('chat message', this.body);

                    this.sendNotificationToAllUsersDialog = false;
                    // this.getNotifications();
                    Push.create(
                        this.title,
                        {
                            body: this.body,
                            link: this.url,
                            requireInteraction: this.requireInteraction,
                        }
                    );
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        sendNotificationFromSocketIoServer: function(data) {
            this.overlay = true;
            this.axios.post(
                '/api/send_notification',
                {
                    user_id: this.$store.state.user.id,
                    title: 'Msg from Socket.io',
                    body: data,
                    url: '-',
                    requireInteraction: false,
                }
            ).then(response => {
                if( response.status === 200 ) {
                    this.getNotifications();
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        nl2br: function(value) {
            return value.replace(/\n/g, '<br/>');
        },
        seenNotification: function(item) {
            this.overlay = true;
            this.axios.post(
                '/api/seen_notification',
                {
                    id: item.id,
                }
            ).then(response => {
                if(response.status === 200) {
                    this.getNotifications();
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        }
    },
    filters: {
        formatDate: function (date) {
            return date !== null ? moment(date).format("MM-DD-YYYY hh:mm:ss A") : '';
        },
    },
}
</script>
