<template>
    <v-container>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title>Destinations</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-data-table
                    :headers="destinationHeaders"
                    :items="destinations"
                    :loading="loading"
                >
                    <template v-slot:top>
                        <v-select
                            outlined
                            dense
                            placeholder="Status"
                            :items="statuses"
                            item-text="status"
                            item-value="status"
                            v-model="defaultStatus"
                        ></v-select>
                        <v-btn
                            block
                            dark
                            color="red"
                            class="lighten-3"
                            @click="addNewDestination()"
                        >
                            add new destination
                        </v-btn>
                    </template>
                    <template v-slot:[`item.patterns`]="{ item }">
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <!-- <th
                                            style="background-color: aquamarine;"
                                        >Type</th> -->
                                        <th>Size#1</th>
                                        <th>Size#2</th>
                                        <th>Size#3</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="containerType, i in containerTypes"
                                        :key="i"
                                    >
                                        <!-- <td
                                            style="background-color: aquamarine;"
                                        >
                                            <v-checkbox
                                                :label="containerType.type"
                                                dense
                                            ></v-checkbox>
                                        </td> -->
                                        <td
                                            v-for="containerSize, j in containerSizes"
                                            :key="j"
                                        >
                                            <v-checkbox
                                                :label="item.destination_type + '_' + containerType.code + '_' + containerSize.size"
                                                v-model="editedPatterns[item.destination_type + '_' + containerType.code + '_' + containerSize.size]"
                                                @change="updatePatterns()"
                                            ></v-checkbox>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <!-- <v-switch
                            v-model="checkAll"
                            :label="`CheckAll: ${checkAll.toString()}`"
                        ></v-switch> -->
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    color="orange"
                                    v-on="on"
                                    @click="editDestination(item)"
                                >mdi-pencil</v-icon>
                            </template>
                            <span>Edit Destination</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    v-if="defaultStatus == 'active'"
                                    color="red"
                                    v-on="on"
                                    @click="softDeleteDestination(item)"
                                >mdi-delete</v-icon>
                            </template>
                            <span>Delete Destination</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    v-if="defaultStatus == 'inactive'"
                                    color="green"
                                    v-on="on"
                                    @click="restoreDestination(item)"
                                >mdi-restore</v-icon>
                            </template>
                            <span>Restore Destination</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            loading: false,
            // checkAll: true,
            destinations: [],
            containerSizes: [],
            containerTypes: [],
            editedPatterns: {},
            destinationHeaders: [
                {
                    text: "DestintionId",
                    value: "destination_id",
                    sortable: true,
                },
                {
                    text: "DestintionType",
                    value: "destination_type",
                    sortable: true,
                },
                {
                    text: "Patterns",
                    value: "patterns",
                    sortable: true,
                },
                {
                    text: "Actions",
                    value: "actions",
                    sortable: false,
                }
            ],
            defaultStatus: 'active',
            statuses: [
                {
                    status: 'active',
                },
                {
                    status: 'inactive'
                }
            ]
        }
    },
    watch: {
        defaultStatus: function() {
            // console.log(this.defaultStatus);
            this.getDestinations(this.defaultStatus);
        },
        // checkAll: function() {
        //     console.log(this.checkAll);
        // }
        // editedPatterns: {
        //     handler(newValue, oldValue) {
        //         console.log(this.editedPatterns);
        //     },
        //     deep: true,
        // }
    },
    mounted() {
        this.getDestinations(this.defaultStatus);
    },
    methods: {
        getContainerSizes: function() {
            this.overlay = true;
            this.loading = true;
            axios.post(
                '/api/get_container_sizes',
            ).then(response => {
                    this.containerSizes = response.data;

                    for( let dest of this.destinations ) {
                        // console.log(dest.destination_type);
                        for( let type of this.containerTypes ) {
                            // console.log(type.code)
                            for( let size of this.containerSizes ) {
                                // console.log(size.size);
                                this.editedPatterns[dest.destination_type + '_' + type.code + '_' + size.size] = JSON.parse(dest.patterns)[dest.destination_type + '_' + type.code + '_' + size.size].active;
                            }
                        }
                    }

                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        },
        getContainerTypes: function() {
            this.overlay = true;
            this.loading = true;
            axios.post(
                '/api/get_container_types',
            ).then(response => {
                    this.containerTypes = response.data;
                    // console.log(response.data)
                    this.getContainerSizes();
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        },
        getDestinations: function(status) {
            this.overlay = true;
            this.loading = true;
            axios.post(
                '/api/get_destinations',
                {
                    status: status,
                }
            ).then(response => {
                    this.destinations = response.data;
                    // console.log(response.data)
                    this.getContainerTypes();

                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        },
        addNewDestination: function() {
            const dest = prompt('Input the name of the destination.');
            let p = {};

            for( let type of this.containerTypes ) {
                for( let size of this.containerSizes ) {
                    // console.log(dest + '_' + type.code + '_' + size.size);
                    p[dest + '_' + type.code + '_' + size.size] =
                        {
                            'destination_type': dest,
                            'type': type.code,
                            'size': size.size,
                            'active': true,
                        }
                    // console.log(p)
                }
            }
            // console.log(JSON.stringify(p));
            this.overlay = true;
            axios.post(
                '/api/add_new_destination',

                {
                    destination: dest,
                    patterns: JSON.stringify(p),
                    created_by: this.$store.state.user.id,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            ).then(response => {
                if(response.status == 200) {
                    this.getDestinations(this.defaultStatus);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        editDestination: function(item) {
            const dest = prompt('Input the name of the destination.');
            this.overlay = true;
            axios.post(
                '/api/update_destination',
                {
                    id: item.id,
                    destination: dest,
                    updated_by: this.$store.state.user.id,
                }
            ).then(response => {
                if(response.status == 200) {
                    this.getDestinations(this.defaultStatus);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        softDeleteDestination: function(item) {
            this.overlay = true;
            axios.post(
                '/api/soft_delete_destination',
                {
                    id: item.id,
                    updated_by: this.$store.state.user.id,
                }
            ).then(response => {
                if(response.status == 200) {
                    this.getDestinations(this.defaultStatus);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        restoreDestination: function(item) {
            this.overlay = true;
            axios.post(
                '/api/restore_destination',
                {
                    id: item.id,
                    updated_by: this.$store.state.user.id,
                }
            ).then(response => {
                if(response.status == 200) {
                    this.getDestinations(this.defaultStatus);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        updatePatterns: function() {
            console.log(this.editedPatterns);
            this.overlay = true;
            axios.post(
                '/api/update_patterns',
                {
                    editedPatterns: this.editedPatterns,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            ).then(response => {
                if(response.status == 200) {
                    console.log(response.data);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        }
    }
}
</script>
