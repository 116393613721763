<template>
  <div>
    <v-container>
      <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card class="mx-auto px-2 pt-2">
          <v-toolbar flat dark class="indigo lighten-3">
              <v-toolbar-title>Sales</v-toolbar-title>
          </v-toolbar>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="payments"
            :loading="loading"
            :items-per-page="itemsPerPage"
            fixed-header
            height="50vh"
            hide-default-footer
          >
            <template v-slot:top>
                <v-btn
                    color="red"
                    class="lighten-2 my-2"
                    dark
                    block
                    @click="deleteUnnecessaryPayments()"
                >Delete Unnecessary Payments</v-btn>
              <v-tabs
                  v-model="tab"
                  center-active
                  dark
              >
                <v-tabs-slider lider color="yellow"></v-tabs-slider>
                <v-tab
                  v-for="(status, i) in statuses"
                  :key="i"
                >
                  {{status}}
                </v-tab>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            @click="generateCSV"
                        >
                            <v-icon>mdi-file-delimited</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        Generate CSV
                    </span>
                </v-tooltip>
              </v-tabs>
                <!--Data Picker start-->
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="dateRangeText"
                            label="Date Rnage"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            @click="dates = []"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="dates"
                        range
                    ></v-date-picker>
                </v-menu>
                <!--Data Picker finish-->
              <v-text-field
                  class="my-2"
                  prepend-inner-icon="mdi-magnify"
                  v-model="search"
                  outlined
                  dense
                  placeholder="Search by TransactionId or Container#"
                  @keyup="searchByKeyword()"
              ></v-text-field>
              # of records: <span style="color: red; font-weight: bold;">{{totalNumberOfRecords}}</span>
            </template>
            <template v-slot:[`item.description`]="{ item }" v-if="tab === 1 && $store.state.user.role === 'su'">
              <a @click="openDuplicatePaymentsDialog(item.description)">{{ item.description }}</a>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              {{ item.created_at | formatDate }}
            </template>
            <template v-slot:[`item.updated_at`]="{ item }">
              {{ item.updated_at | formatDate }}
            </template>
          </v-data-table>
          <div class="text-center pt-2">
              <v-pagination
                  v-model="page"
                  :length="pageCount"
                  total-visible="10"
                  @input="changePage()"
              ></v-pagination>
          </div>
        </v-card-text>
      </v-card>

      <!--duplicatePaymentsDialog start-->
        <v-dialog v-model="duplicatePaymentsDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Duplicate Payment Records -- {{currentContainerNumber}} --</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="duplicatePaymentsDialog = false"
                        >
                        <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-data-table
                            :headers="duplicatePaymentHeaders"
                            :items="duplicatePayments"
                            :loading="loading"
                        >
                        <template v-slot:[`item.created_at`]="{ item }">
                            {{ item.created_at | formatDate }}
                        </template>
                        <template v-slot:[`item.updated_at`]="{ item }">
                            {{ item.updated_at | formatDate }}
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{on}">
                                    <v-icon
                                        v-on="on"
                                        v-if="item.status !== 'Paid' && okToDelete"
                                        color="red"
                                        @click="deletePayment(item)"
                                    >mdi-delete</v-icon>
                                </template>
                                <span>Delete this payment record</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
      <!--duplicatePaymentsDialog finish-->

    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      overlay: false,
      loading: false,
      tab: 0,
      statuses: [
        'Paid',
        'Unpaid'
      ],
      search: null,
      pageCount: 0,
      page: 1,
      itemsPerPage: 30,
      totalNumberOfRecords: null,
      headers: [
        {
          text: "TransactionId",
          value: "txnid",
          sortable: false,
        },
        {
          text: "Reference#",
          value: "refno",
          sortable: false,
        },
        {
          text: "Email",
          value: "email",
          sortable: false,
        },
        {
          text: "MerchantId",
          value: "merchantid",
          sortable: false,
        },
        {
          text: "Amount",
          value: "amount",
          sortable: false,
        },
        {
          text: "Currency",
          value: "ccy",
          sortable: false,
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
        },
        {
          text: "Container#",
          value: "description",
          sortable: false,
        },
        {
          text: "Message",
          value: "message",
          sortable: false,
        },
        {
          text: "CreatedAt",
          value: "created_at",
          sortable: false,
        },
        {
          text: "UpdatedAt",
          value: "updated_at",
          sortable: false,
        },
      ],
      payments: [],
      menu: false,
    //   dates: [
    //     `${moment().format('YYYY')}-${moment().format('MM')}-01`,
    //     `${moment().format('YYYY')}-${moment().format('MM')}-${moment().daysInMonth()}`
    // ],
    dates: [
        `${moment().format('YYYY')}-${moment().format('MM')}-01`,
        `${moment().format('YYYY')}-${moment().format('MM')}-${moment().daysInMonth()}`
    ],
    duplicatePaymentsDialog: false,
    currentContainerNumber: null,
    duplicatePaymentHeaders: [
        {
          text: "TransactionId",
          value: "txnid",
          sortable: false,
        },
        {
          text: "RefNo",
          value: "refno",
          sortable: false,
        },
        {
          text: "Email",
          value: "email",
          sortable: false,
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
        },
        {
          text: "ContainerNumber",
          value: "description",
          sortable: false,
        },
        {
          text: "CreatedAt",
          value: "created_at",
          sortable: false,
        },
        {
          text: "UpdatedAt",
          value: "updated_at",
          sortable: false,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
    ],
    duplicatePayments: [],
    okToDelete: false,
    }
  },
  mounted() {
    this.getPayments(this.tab);
  },
  methods: {
    getPayments: function(status) {
        this.overlay = true;
        this.loading = true;
        this.axios.post(
        '/api/get_payments',
        {
            itemsPerPage: this.itemsPerPage,
            page: this.page,
            search: this.search,
            status: status,
            dates: this.dates
        }
        ).then(response => {
        if(response.status === 200) {
            this.payments = response.data.data;
            this.pageCount = Math.ceil(response.data.total / this.itemsPerPage);
            this.totalNumberOfRecords = response.data.total;
        }
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            this.overlay = false;
            this.loading = false;
        });
    },
    changePage: function() {
      this.getPayments(this.tab)
    },
    searchByKeyword: function() {
        this.page = 1;
        this.getPayments(this.tab);
    },
    generateCSV: function() {
      this.downloading = true;
      this.overlay = true;
      this.axios.post(
        '/api/generate_payments_csv',
        {
          tab: this.tab,
          dates: this.dates
        },
        {
        responseType: "blob",
        },
      )
        .then(response => {
            // console.log(response.data)
          this.downloading = false;
          this.overlay = false;
          const fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "text/csv" })
          );
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "payments.csv");
          fileLink.target = "_blank";
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
        }).catch(error => {
          console.log(error);
        });
    },
    openDuplicatePaymentsDialog: function(container_number) {
        this.duplicatePaymentsDialog = true;
        this.currentContainerNumber = container_number;
        this.getDuplicatePayments();
    },
    getDuplicatePayments: function() {
        this.overlay = true;
        this.loading = true;
        this.axios.post(
        '/api/get_duplicate_payments',
        {
            container_number: this.currentContainerNumber
        }
        ).then(response => {
            if(response.status === 200) {
                this.duplicatePayments = response.data;
                let paids = this.duplicatePayments.filter(function(value) {
                    return value.status === 'Paid';
                });
                if(paids.length > 0) {
                    this.okToDelete = true;
                } else {
                    this.okToDelete = false;
                }
            }
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            this.overlay = false;
            this.loading = false;
        });
    },
    deletePayment: function(item) {
        if(!confirm('Is it ok to delete this item?')) {
            return false;
        }

        this.overlay = true;
        this.loading = true;
        this.axios.post(
        '/api/delete_payment',
        {
            txnid: item.txnid
        }
        ).then(response => {
            if(response.status === 200) {
                this.getDuplicatePayments();
                this.getPayments(this.tab);
            }
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            this.overlay = false;
            this.loading = false;
        });
    },
    deleteUnnecessaryPayments: function() {
        this.overlay = true;
        this.loading = true;    
        axios.post(
            '/api/delete_unnecessary_payments',
            {
                itemsPerPage: this.itemsPerPage,
                page: this.page,
                search: this.search,
                dates: this.dates
            }
        ).then(response => {
            if( response.status == 200 ) {
                // console.log(response.data);
                this.getPayments(this.tab);
            }
        }).catch(error => {
            console.log(error);
        }).finally( () => {
            this.overlay = false;
            this.loading = false;
        });
    }
  },
  filters: {
      formatDate: function (date) {
          return date !== null ? moment(date).format("MM-DD-YYYY hh:mm:ss A") : '';
      },
  },
  watch: {
    tab: function() {
        this.page = 1;
        this.getPayments(this.tab);
    },
    dates: function() {
        if (this.dates.length == 0) {
            return false;
        } else if (this.dates.length == 2) {
            if ( this.dates[0] > this.dates[1] ) {
                alert('Invalid date selection');
                this.dates = [];
                return false;
            }
        }

        this.page = 1;
        this.getPayments(this.tab);
    }
  },
  computed: {
    dateRangeText() {
        return this.dates.join(' ~ ');
    }
  }
}
</script>
