<template>
  <div>
    <v-container>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card class="mx-auto px-2 pt-2">
                <v-toolbar flat dark class="indigo lighten-3">
                    <v-toolbar-title>Transaction Maintenance</v-toolbar-title>
                </v-toolbar>
                <v-tabs
                    v-model="tab"
                    center-active
                    dark
                >
                  <v-tabs-slider color="yellow"></v-tabs-slider>
                  <v-tab
                      v-for="(status, i) in statuses"
                      :key="i"
                  >
                      {{status.status_name}}
                  </v-tab>
                </v-tabs>
                <v-card-text>
                    <v-data-table
                    :headers="transactionHeaders"
                    :items="transactions"
                    :loading="loading"
                    :items-per-page="-1"
                    :search="search"
                    fixed-header
                    height="50vh"
                    >
                    <template v-slot:top>
                      <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title>Transactions Table *{{statuses[tab].status_name}}</v-toolbar-title>
                      </v-toolbar>
                      # of records: <span style="color: red; font-weight: bold;">{{transactions.length}}</span>
                      <v-text-field
                            class="my-2"
                            prepend-inner-icon="mdi-magnify"
                            v-model="search"
                            outlined
                            placeholder="Search by transaction_id or container#"
                            dense
                        ></v-text-field>
                        <v-btn 
                          block 
                          class="red lighten-2" 
                          dark
                          v-if="tab == 0 && transactions.length > 0"
                          @click="deleteExpiredTransactionRecords()"
                        >Delete Expired Transaction(s)</v-btn>
                    </template>
                    <template v-slot:[`item.created_at`]="{ item }">
                      {{item.created_at | formatDate}}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-show="tab === 0"
                            v-on="on"
                            color="red"
                            @click="deleteItem(item)"
                          >mdi-delete</v-icon>
                        </template>
                        <span>Delete Item</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-show="tab === 1"
                            v-on="on"
                            color="green"
                            @click="restoreItem(item)"
                          >mdi-delete-restore</v-icon>
                        </template>
                        <span>Restore Item</span>
                      </v-tooltip>
                    </template>
                </v-data-table>
                </v-card-text>
                <!-- <v-card-text>
                    <v-data-table
                    :headers="containerHeaders"
                    :items="containers"
                    :loading="loading"
                    :items-per-page="3000"
                    fixed-header
                    height="50vh"
                    hide-default-footer
                    >
                    <template v-slot:top>
                      <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title>Containers Table *{{statuses[tab].status_name}}</v-toolbar-title>
                      </v-toolbar>
                      # of records: <span style="color: red; font-weight: bold;">{{containers.length}}</span>
                    </template>
                    <template v-slot:[`item.created_at`]="{ item }">
                      {{item.created_at | formatDate}}
                    </template>
                </v-data-table>
                </v-card-text> -->
                <!-- <v-card-text v-show="tab == 0">
                    <v-data-table
                    :headers="paymentHeaders"
                    :items="payments"
                    :loading="loading"
                    :items-per-page="3000"
                    fixed-header
                    height="50vh"
                    hide-default-footer
                    >
                    <template v-slot:top>
                      <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title>Payments Table *{{statuses[tab].status_name}}</v-toolbar-title>
                      </v-toolbar>
                      # of records: <span style="color: red; font-weight: bold;">{{payments.length}}</span>
                    </template>
                    <template v-slot:[`item.created_at`]="{ item }">
                      {{item.created_at | formatDate}}
                    </template>
                </v-data-table>
                </v-card-text> -->
            </v-card>
        </v-container>
  </div>
</template>
<script>
import { assertExpressionStatement } from '@babel/types';
export default {
  data() {
    return {
      overlay: false,
      loading: false,
      tab: 0,
      search: null,
      statuses: [
        {
          status_name: "Expired"
        },
        // {
        //   status_name: "Deleted"
        // }
      ],
      transactionHeaders: [
        {
          text: "TransactionId",
          value: "transaction_id",
          sortable: false
        },
        {
          text: "Container#",
          value: "container_number",
          sortable: false
        },
        {
          text: "StatusId",
          value: "status_id",
          sortable: false
        },
        {
          text: "IsPreadvised",
          value: "is_preadvised",
          sortable: false
        },
        {
          text: "CreatedAt",
          value: "created_at",
          sortable: false
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false
        },
      ],
      transactions: [],
    //   containerHeaders: [
    //     {
    //       text: "Container#",
    //       value: "container_number",
    //       sortable: false
    //     },
    //     {
    //       text: "CreatedAt",
    //       value: "created_at",
    //       sortable: false
    //     },
    //   ],
    //   containers: [],
      paymentHeaders: [
        {
          text: "TxnId",
          value: "txnid",
          sortable: false
        },
        {
          text: "Ref#",
          value: "refno",
          sortable: false
        },
        {
          text: "Status",
          value: "status",
          sortable: false
        },
        {
          text: "Container#",
          value: "description",
          sortable: false
        },
        {
          text: "CreatedAt",
          value: "created_at",
          sortable: false
        },
      ],
      payments: []
    }
  },
  mounted() {
    this.loadTransactions();
    // this.loadContainers();
    // this.loadPayments();
  },
  methods: {
    loadTransactions: function() {
      this.overlay = true;
      this.loading = true;
      axios.post(
        '/api/get_transactions_for_record_maintenance'
      ).then(response => {
          if(response.status === 200) {
            this.transactions = response.data;
            // console.log(response.data);
          }
        }).catch(error => {
          console.log(error);
        }).finally(() => {
          this.overlay = false;
          this.loading = false;
        });
    },
    // loadContainers: function() {
    //   this.overlay = true;
    //   this.loading = true;
    //   axios.post(
    //     '/api/get_containers_for_record_maintenance'
    //   ).then(response => {
    //       if(response.status === 200) {
    //         this.containers = response.data;
    //         // console.log(response.data);
    //       }
    //     }).catch(error => {
    //       console.log(error);
    //     }).finally(() => {
    //       this.overlay = false;
    //       this.loading = false;
    //     });
    // },
    loadPayments: function() {
      this.overlay = true;
      this.loading = true;
      axios.post(
        '/api/get_payments_for_record_maintenance'
      ).then(response => {
          if(response.status === 200) {
            this.payments = response.data;
            // console.log(response.data);
          }
        }).catch(error => {
          console.log(error);
        }).finally(() => {
          this.overlay = false;
          this.loading = false;
        });
    },
    loadDeletedTransactions: function() {
      this.overlay = true;
      this.loading = true;
      axios.post(
        '/api/get_deleted_transactions_for_record_maintenance'
      ).then(response => {
          if(response.status === 200) {
            this.transactions = response.data;
            // console.log(response.data);
          }
        }).catch(error => {
          console.log(error);
        }).finally(() => {
          this.overlay = false;
          this.loading = false;
        });
    },
    // loadDeletedContainers: function() {
    //   this.overlay = true;
    //   this.loading = true;
    //   axios.post(
    //     '/api/get_deleted_containers_for_record_maintenance'
    //   ).then(response => {
    //       if(response.status === 200) {
    //         this.containers = response.data;
    //         // console.log(response.data);
    //       }
    //     }).catch(error => {
    //       console.log(error);
    //     }).finally(() => {
    //       this.overlay = false;
    //       this.loading = false;
    //     });
    // },
    // loadDeletedPayments: function() {
    //   this.overlay = true;
    //   this.loading = true;
    //   axios.post(
    //     '/api/get_deleted_payments_for_record_maintenance'
    //   ).then(response => {
    //       if(response.status === 200) {
    //         this.payments = response.data;
    //         // console.log(response.data);
    //       }
    //     }).catch(error => {
    //       console.log(error);
    //     }).finally(() => {
    //       this.overlay = false;
    //       this.loading = false;
    //     });
    // },
    deleteItem: function(item) {
      if(!confirm('Is it ok to delete the item?')) {
        return false;
      }

      item.created_at = moment(item.created_at).format('YYYY-MM-DD HH:mm:ss');
      item.updated_at = moment(item.updated_at).format('YYYY-MM-DD HH:mm:ss');

      this.overlay = true;
      this.loading = true;
      axios.post(
        '/api/delete_expired_transaction',
        {
          item: item
        }
      ).then(response => {
          if(response.status === 200) {
            console.log(response.data);
            this.loadTransactions();
            this.loadContainers();
            this.loadPayments();
          }
        }).catch(error => {
          console.log(error);
        }).finally(() => {
          this.overlay = false;
          this.loading = false;
        });
    },
    restoreItem: function(item) {
      if(!confirm('Is it ok to restore the item?')) {
        return false;
      }

      item.created_at = moment(item.created_at).format('YYYY-MM-DD HH:mm:ss');
      item.updated_at = moment(item.updated_at).format('YYYY-MM-DD HH:mm:ss');

      this.overlay = true;
      this.loading = true;
      axios.post(
        '/api/restore_deleted_transaction',
        {
          item: item
        }
      ).then(response => {
          if(response.status === 200) {
            console.log(response.data);
            this.loadDeletedTransactions();
            this.loadDeletedContainers();
            this.loadDeletedPayments();
          }
        }).catch(error => {
          console.log(error);
        }).finally(() => {
          this.overlay = false;
          this.loading = false;
        });
    },
    deleteExpiredTransactionRecords: function() {
      if( !confirm('Is it ok to delete all the expired transaction record(s)?') ) {
        return false;
      }
      this.overlay = true;
      this.loading = true;      
      this.axios.post(
        '/api/delete_expired_transaction_records'
      ).then(response => {
        console.log(response.data);
        if( response.data == 'ok' ) {
          this.loadTransactions();
        }
      }).catch(error => {
        console.log(error);
      }).finally(() => {
          this.overlay = false;
          this.loading = false;        
      });
    }
  },
  filters: {
      formatDate: function (date) {
          return date !== null ? moment(date).format("MM-DD-YYYY hh:mm:ss A") : '';
      },
  },
  watch: {
    tab: function() {
      if(this.tab == 0) {
        this.loadTransactions();
        // this.loadContainers();
        // this.loadPayments();
      } else {
        this.loadDeletedTransactions();
        // this.loadDeletedContainers();
        // this.loadDeletedPayments();
      }
    },
  }
}
</script>
