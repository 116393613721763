<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card class="elevation-1">
            <v-form ref="form">
              <v-toolbar color="indigo" class="lighten-2" dark flat>
                <v-toolbar-title>Sign-up form</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-text-field
                  v-model="name"
                  label="Name"
                  name="name"
                  prepend-icon="person"
                  type="text"
                  :rules="rules"
                />

                <v-text-field
                  label="Email"
                  name="email"
                  prepend-icon="email"
                  type="text"
                  v-model="email"
                  :rules="emailRules"
                  @blur="checkEmail"
                />

                <v-text-field
                  v-model="organization"
                  label="Organization"
                  name="organization"
                  prepend-icon="mdi-office-building"
                  type="text"
                  :rules="rules"
                />
                <v-text-field
                  v-model="password"
                  label="Password"
                  name="password"
                  prepend-icon="lock"
                  type="password"
                  :rules="rules"
                />
                <v-text-field
                  v-model="confirm_password"
                  label="Same password again"
                  name="confirm_password"
                  prepend-icon="lock"
                  type="password"
                  :rules="rules"
                />
                <v-btn
                  @click="termsAndConditioins = !termsAndConditioins"
                  color="yellow"
                  ><v-icon>mdi-alert</v-icon>Terms and Conditions</v-btn
                >
                <v-checkbox
                  label="*Agree with our Terms and Conditions?"
                  v-model="agreeCheckbox"
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="indigo"
                  class="lighten-2"
                  dark
                  @click="sendPIN"
                  :disabled="!agreeCheckbox"
                  >Sign-up</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog width="80%" v-model="termsAndConditioins" fullscreen>
      <v-card>
        <v-container>
            <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title
                >Terms and Conditions</v-toolbar-title
                >
                <v-spacer></v-spacer>
                <v-btn
                    icon
                    dark
                    @click="termsAndConditioins = false"
                >
                <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
          <v-card-title>BrightLightVenturesPh: Terms and Conditions</v-card-title>
          <v-card-text>
            Last updated: 09-04-2022<br /><br />Please read these Terms and
            carefully before using the http://brightlightventuresph.com and
            mobile application operated by Bright Light Ventures Inc.
            Your access to and use of the Service is conditioned on your
            acceptance of and compliance with these Terms. These Terms apply to
            all visitors, users and others who access or use the Service. By
            accessing or using the Service you agree to be bound by these Terms.
            If you disagree with any part of the terms then you may not access
            the Service.
          </v-card-text>
          <v-card-subtitle>Confidentiality</v-card-subtitle>
          <v-card-text>
            <ul>
              <li>
                The Recipient will receive and maintain all Confidential
                Information disclosed or delivered to it by the Discloser in
                strict confidence and will only use it for the Purpose and
                disclose it to those persons who are officers, employees or
                agents of the Recipient to whom it is essential to disclose the
                same to accomplish the Purpose, provided such persons also agree
                to be bound by the terms and conditions of this Agreement.
              </li>
              <li>
                The Recipient agrees that the Confidential Information disclosed
                to it pursuant to this Agreement will:
              </li>
              <li>Be used only for the Purpose;</li>
              <li>
                Not be used, or disclosed or disseminated by the Recipient for
                any other purpose whatsoever, other than with the prior written
                permission of the Discloser; and
              </li>
              <li>
                Nothing in this Agreement either expressly or by implication may
                be construed as granting to or conferring on the RecipientFor
                the avoidance of doubt, the Recipient acknowledges that the
                Confidential Information must not be disclosed to any of the
                Recipient’s service and third-party providers without the prior
                written consent of the Discloser. Where such approval is
                provided, the Recipient must ensure that such providers agree to
                be bound by the terms and conditions of this Agreement. any
                rights by license, estoppel or otherwise in relation to any
                proprietary drawings, plans, ideas or methods, any patent,
                design or other right covering any invention or discovery or any
                copyright, trademark or trade secret right which is disclosed or
                delivered as Confidential Information.
              </li>
              <li>
                The Recipients obligations of confidentiality will continue
                until advised by the Discloser that the Confidential Information
                is no longer confidential.
              </li>
            </ul>
          </v-card-text>
          <v-card-subtitle>Ownership</v-card-subtitle>
          <v-card-text>
            <ul>
              <li>
                No property in any Confidential Information disclosed or
                delivered by the Discloser will pass or be transferred to the
                Recipient or any third person by reason of this Agreement.
              </li>
            </ul>
          </v-card-text>
          <v-card-subtitle>Content</v-card-subtitle>
          <v-card-text
            >Our Service allows you to upload, store, share and otherwise make
            available certain information, such as documents, images, etc. You
            are responsible for your data upload and sharing in the system as
            will be shared and available to the respective team or group to
            handle the information.</v-card-text
          >
          <v-card-subtitle>Links to Other Web Sites</v-card-subtitle>
          <v-card-text
            >Our Service may contain links to third-party web sites or services
            that are not owned or controlled by Bright Light Ventures Inc.
            Bright Light Ventures Inc. has no control over, and assumes no
            responsibility for, the content, privacy policies, or practices of
            any third party web sites or services. You further acknowledge and
            agree that Bright Light Ventures Inc. shall not be responsible or
            liable, directly or indirectly, for any damage or loss caused or
            alleged to be caused by or in connection with use of or reliance on
            any such content, goods or services available on or through any such
            web sites or services.
          </v-card-text>
          <v-card-subtitle>Changes</v-card-subtitle>
          <v-card-text
            >We reserve the right, at our sole discretion, to modify or replace
            these Terms at any time. If a revision is material we will try to
            provide at least 30 days’ notice prior to any new terms taking
            effect. What constitutes a material change will be determined at our
            sole discretion.</v-card-text
          >
          <v-card-subtitle>Contact Us</v-card-subtitle>
          <v-card-text
            >If you have any questions about these Terms, please contact us.</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
                <v-btn
                    @click="termsAndConditioins = false"
                >
                <v-icon>mdi-close</v-icon>
                Close
                </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      overlay: false,
      name: null,
      email: null,
      organization: null,
      password: null,
      confirm_password: null,
      agreeCheckbox: false,
      termsAndConditioins: false,
      rules: [(v) => !!v || "Required"],
      emailRules: [(v) => /.+@.+/.test(v) || "Invalid Email address"],
    };
  },
  created() {
    // const currentURL = window.location.origin;
    // this.agent_code = currentURL.split("://")[1].split(".")[0];
    // if (
    //   currentURL
    //     .split("://")[1]
    //     .split(".")[0]
    //     .match(/localhost/)
    // ) {
    //   this.agent_code = "trial";
    // }
    //console.log(this.agent_code);
  },
  methods: {
    checkEmail: function () {
      this.axios
        .post("/api/check_email", {
          email: this.email,
        })
        .then((response) => {
          if (response.data) {
            const payload = {
              title: "The email address exists",
              msg: "You can not provide the email address to make a new account...",
            };
            this.$store.commit("showAlert", payload);
            this.name = null;
            this.email = null;
            return false;
          }
        });
    },
    sendPIN: function () {
      if (!this.$refs.form.validate()) {
        return false;
      }

      if (
        confirm(
          "If you click OK, onetime PIN(valid within 24 hours) will be sent to your Email account.\r\n After receiving go back to this site by pressing the URL indicated in the email and then provide the PIN in designated text field to validate your user registration.\r\nThank you."
        )
      ) {
        this.overlay = true;
        const params = {
          name: this.name,
          email: this.email,
          organization: this.organization,
        //   agent_code: this.agent_code,
          password: this.password,
          password_confirmation: this.confirm_password,
          role: "user",
        };
        axios
          .post("/api/register_user", params)
          .then((response) => {
            this.$store.commit("assignAccessToken", response.data.access_token);
            this.$router.push("/verify_account/" + this.email);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>
