<template>
  <div>
    <v-container>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card class="mx-auto px-2 pt-2">
                <v-toolbar flat dark class="indigo lighten-3">
                    <v-toolbar-title>Container Maintenance</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-data-table
                    :headers="containerHeaders"
                    :items="containers"
                    :loading="loading"
                    :items-per-page="itemsPerPage"
                    :search="search"
                    fixed-header
                    height="50vh"
                    hide-default-footer
                    >
                    <template v-slot:top>
                      <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title>Containers Table</v-toolbar-title>
                      </v-toolbar>
                      # of records: <span style="color: red; font-weight: bold;">{{ totalNumberOfRecords }}</span>
                      <v-text-field
                            class="my-2"
                            prepend-inner-icon="mdi-magnify"
                            v-model="search"
                            outlined
                            placeholder="Search by container#"
                            dense
                            @keyup="searchByKeyword()"
                        ></v-text-field>
                    </template>
                    <template v-slot:[`item.created_at`]="{ item }">
                      {{item.created_at | formatDate}}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            color="orange"
                            @click="openUpdateContainerDialog(item)"
                          >mdi-pencil</v-icon>
                        </template>
                        <span>Update Container</span>
                      </v-tooltip>
                    </template>
                </v-data-table>
                <div class="text-center pt-2">
                    <v-pagination
                        v-model="page"
                        :length="pageCount"
                        total-visible="10"
                        dark
                        @input="changePage()"
                    ></v-pagination>
                </div>
                </v-card-text>
            </v-card>
        </v-container>
        <!-- updateContainerDialog start -->
        <v-dialog v-model="updateContainerDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        lbar-title
                        >Update Container: {{ currentContainerNumber }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="updateContainerDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <v-select
                        outlined
                        dense
                        label="Container Type"
                        v-model="currentContainerType"
                        :items="containerTypes"
                        item-text="type"
                        item-value="container_type_id"
                      ></v-select>
                      <v-select
                        outlined
                        dense
                        label="Container Size"
                        v-model="currentContainerSize"
                        :items="containerSizes"
                        item-text="size"
                        item-value="container_size_id"
                      ></v-select>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            block
                            color="red"
                            class="lighten-2"
                            dark
                            @click="updateContainer()"
                        >
                            <v-icon>mdi-update</v-icon> update
                        </v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </v-dialog>
        <!-- updateContainerDialog end -->
  </div>
</template>
<script>
import { assertExpressionStatement } from '@babel/types';
export default {
  data() {
    return {
        overlay: false,
        loading: false,
        search: null,
        pageCount: 0,
        page: 1,
        itemsPerPage: 30,
        totalNumberOfRecords: null,
        containerHeaders: [
        {
            text: "Container#",
            value: "container_number",
            sortable: false
        },
        {
            text: "CompanyName",
            value: "company.name",
            sortable: false
        },
        {
            text: "ContainerSize",
            value: "size.size",
            sortable: false
        },
        {
            text: "ContainerType",
            value: "type.type",
            sortable: false
        },
        {
            text: "CreatedBy",
            value: "user.email",
            sortable: false
        },
        {
            text: "CreatedAt",
            value: "created_at",
            sortable: false
        },
        {
            text: "Actions",
            value: "actions",
            sortable: false
        },
        ],
        containers: [],
        updateContainerDialog: false,
        currentContainerNumber: null,
        currentContainerType: null,
        currentContainerSize: null,
        containerTypes: [],
        containerSizes: [],
    }
  },
  mounted() {
    this.loadContainers();
    this.getContainerTypes();
    this.getContainerSizes();
  },
  methods: {
    loadContainers: function() {
      this.overlay = true;
      this.loading = true;
      axios.post(
        '/api/get_containers_for_maintenance',
        {
            page: this.page,
            itemsPerPage: this.itemsPerPage,
            search: this.search,
        }
      ).then(response => {
          if(response.status === 200) {
            this.containers = response.data.data;
            this.totalNumberOfRecords = response.data.total;
            this.pageCount = Math.ceil(response.data.total / this.itemsPerPage);
            // console.log(response.data);
          }
        }).catch(error => {
          console.log(error);
        }).finally(() => {
          this.overlay = false;
          this.loading = false;
        });
    },
    openUpdateContainerDialog: function(item) {
    //   console.log(item)
      this.updateContainerDialog = true;
      this.currentContainerNumber = item.container_number;
      this.currentContainerType = item.container_type_id;
      this.currentContainerSize = item.container_size_id;
    },
    updateContainer: function() {
      if(!confirm('Is it ok to delete the item?')) {
        return false;
      }

      this.overlay = true;
      this.loading = true;
      axios.post(
        '/api/update_container',
        {
            container_number: this.currentContainerNumber,
            container_type_id: this.currentContainerType,
            container_size_id: this.currentContainerSize,
            updated_by: this.$store.state.user.id,
            updated_at: moment().format('YYYY-MM-DD HH:mm:ss'),
        }
      ).then(response => {
          if(response.status === 200) {
            this.updateContainerDialog = false;
            this.loadContainers();
          }
        }).catch(error => {
          console.log(error);
        }).finally(() => {
          this.overlay = false;
          this.loading = false;
        });
    },
    getContainerTypes: function() {
        axios.post('/api/get_container_types')
            .then(response => {
                if(response.status == 200) {
                    this.containerTypes = response.data;
                }
            }).catch(error => {
                console.log(error);
            });
    },
    getContainerSizes: function() {
        axios.post('/api/get_container_sizes')
            .then(response => {
                if(response.status == 200) {
                    this.containerSizes = response.data;
                }
            }).catch(error => {
                console.log(error);
            });
    },
    changePage: function() {
        this.loadContainers();
    },
    searchByKeyword: function() {
        this.page = 1;
        this.loadContainers();
    },
  },
  filters: {
      formatDate: function (date) {
          return date !== null ? moment(date).format("MM-DD-YYYY hh:mm:ss A") : '';
      },
  },
}
</script>
