<template>
  <div>

    <v-list>
      <v-list-item
        v-for="(e, i) in emails"
        :key="i"
        link
        @click="getEmailItem(e)"
      >
        {{e}}
      </v-list-item>
    </v-list>
    <v-data-table
      :headers="headers"
      :items="items"

    >
    <template v-slot:[`item.text_body`]="{ item }">
        <pre>{{item.text_body}}</pre>
      </template>    
      <template v-slot:[`item.html_body`]="{ item }">
        <span v-html="item.html_body"></span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          @click="openDialog(item)"
        >mdi-eye</v-icon>
      </template>      
    </v-data-table>
    <!--dialog start-->
    <v-dialog v-model="dialog" fullscreen>
      <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card flat>
        <v-container>
          <v-toolbar flat dark class="indigo lighten-3">
            <v-toolbar-title
            >Email Body</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn
                icon
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
          </v-toolbar>
          <v-card-text>
            <span v-html="emailBody"></span>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>

    <!--dialog end-->
  </div>
</template>
<script>

export default {
  data() {
    return {
      dialog: false,
      overlay: false,
      emails: [],
      headers: [
        {
          text: "From",
          value: "address_from",
          sortable: true,
        },              
        {
          text: "To",
          value: "mail_to",
          sortable: true,
        },
        {
          text: "Subject",
          value: "subject",
          sortable: true,
        },        
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        }                
      ],
      items: [],
      emailBody: null,
    }
  },
  mounted() {
    this.axios.post('/api/get_test1_emails')
      .then(response => {
        // console.log(response.data);
        this.emails = response.data;
      }).catch(error => {
        console.log(error);
      });
    
    this.getEmailItems();
  },
  methods: {
    getEmailItem: function(e) {
      this.axios.post(
        '/api/get_email_item',
        {
          name: e
        }
      )
      .then(response => {
        // console.log(response.data);
        location.href = '/test1_emails';
      }).catch(error => {
        console.log(error);
      });      
    },
    getEmailItems: function() {
      this.axios.post(
        '/api/get_email_items'
      )
      .then(response => {
        this.items = response.data;
      }).catch(error => {
        console.log(error);
      });      
    },
    openDialog: function(item) {
      this.dialog = true;
      if(item.html_body == 0) {
        this.emailBody = '<pre class="text-wrap">' + item.text_body + '</pre>';
      } else {
        this.emailBody = item.html_body;
      }
    }    
  }
}
</script>