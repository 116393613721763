<template>
  <div>
    <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-form>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="12">
            <v-form ref="form">
              <v-card class="elevation-1">
                <v-toolbar class="indigo lighten-3" dark flat>
                  <v-toolbar-title>Payment Form</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    v-model="txnid"
                    label="Transaction ID"
                    name="txnid"
                    prepend-icon="mdi-identifier"
                    type="text"
                    readonly
                    :rules="rules"
                  />
                  <v-text-field
                    v-model="amount"
                    label="Amount(Philippine Peso)"
                    name="password"
                    prepend-icon="mdi-cash-multiple"
                    type="number"
                    :rules="rules"
                    readonly
                  />
                  <v-text-field
                    label="Email"
                    name="email"
                    prepend-icon="mdi-email"
                    type="text"
                    readonly
                    v-model="email"
                    :rules="emailRules"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue lighten-3"
                    dark
                    @click="payNow()"
                    :disabled="disablePayNowButton"
                ><v-icon>mdi-credit-card-outline</v-icon> Pay Now</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <!--reloadAndPayNowAgainDialog start-->
    <v-dialog v-model="reloadAndPayNowAgainDialog">
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card flat>
            <v-container>
                <v-toolbar flat dark class="indigo lighten-3">
                    <v-toolbar-title
                    >Reload the broaser and then Pay Now Again</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        dark
                        @click="reloadAndPayNowAgainDialog = false"
                        >
                        <v-icon>mdi-close</v-icon>
                        </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-alert
                        border="left"
                        colored-border
                        type="warning"
                        elevation="2"
                    >
                    Due to the server error the system can not prceed this process. Please click the following button to retry it again;<br><br>
                    <v-btn
                        class="lighten-2"
                        color="red"
                        block
                        dark
                        @click="reDo"
                    >
                    Redo
                    </v-btn>
                    </v-alert>
                </v-card-text>
            </v-container>
        </v-card>
    </v-dialog>
    <!--reloadAndPayNowAgainDialog finish-->
  </div>
</template>
<script>
export default {
  data() {
    return {
      overlay: false,
      txnid:
        moment().format("mmss") + this.$route.params.transaction_id,
      amount: 165,
      email: this.$route.params.email,
      rules: [(v) => !!v || "Required"],
      emailRules: [(v) => /.+@.+/.test(v) || "Invalid Email address"],
      reloadAndPayNowAgainDialog: false,
      disablePayNowButton: false,
    };
  },
  mounted() {
    this.overlay = true;
    this.axios.post(
      '/api/get_payment',
      {
        transaction_id: this.$route.params.transaction_id,
        container_number: this.$route.params.container_number,
      }
    ).then(response => {
    //   console.log(response.data);
      if(response.status === 200) {
        if(response.data.length) {
          location.href = `/take_picture/${this.$route.params.container_number}/${this.$route.params.transaction_id}/${this.$route.params.email}`;
        }
      }
    }).catch(error => {
      console.log(error);
    }).finally(() => {
      this.overlay = false;
    });
  },
  methods: {
    payNow: function () {
      if (!this.$refs.form.validate()) {
        return false;
      }
      this.disablePayNowButton = true;
      this.axios
        .post("/api/redirect_to_dragonpay", {
          txnid: this.txnid.toUpperCase(),
          transaction_id: this.$route.params.transaction_id.toUpperCase(),
          container_number: this.$route.params.container_number.toUpperCase(),
          amount: this.amount,
          email: this.email,
        })
        .then((response) => {
          const url = response.data;
          if(url) {
            window.open(url, "_self");
            this.disablePayNowButton = false;
          } else {
            this.disablePayNowButton = false;
            this.reloadAndPayNowAgainDialog = true;
          }
        })
        .catch((error) => {
            this.disablePayNowButton = false;
            this.reloadAndPayNowAgainDialog = true;
        });
    },
    reDo: function() {
        this.reloadAndPayNowAgainDialog = false;
        location.reload();
        const payload = {
            title: "Click Pay Now Again",
            msg: "Now you can proceed the payment process...",
        };
        this.$store.commit("showAlert", payload);
    }
  },
};
</script>
