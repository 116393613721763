<template>
    <v-container>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <v-toolbar flat dark class="indigo lighten-3">
                <!-- <v-toolbar-title>Register Container To Be Returned *Pre-advise flow</v-toolbar-title> -->
                <v-toolbar-title>Register Container To Be Returned *For TS Lines</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-form ref="form">
                    <v-card-text>
                        <v-text-field
                            outlined
                            dense
                            label="UserEmailAddress"
                            placeholder="UserEmailAddress"
                            v-model="userEmailAddress"
                            :rules="emailRules"
                        ></v-text-field>
                        <v-text-field
                            outlined
                            dense
                            label="ContainerNumber"
                            placeholder="ContainerNumber"
                            v-model="containerNumber"
                            :rules="rules"
                            @blur="avoidDuplicateEntryOfContainerNumber"
                            @keyup="upperCase"
                            @click="clearInputValues()"
                        ></v-text-field>
                        <v-text-field
                            outlined
                            dense
                            v-show="false"
                            readonly
                            label="ContainerSizeId"
                            v-model="existingContainerSizeId"
                        ></v-text-field>
                        <v-text-field
                            outlined
                            dense
                            v-show="false"
                            readonly
                            label="ContainerTypeId"
                            v-model="existingContainerTypeId"
                        ></v-text-field>
                        <v-text-field
                            outlined
                            dense
                            v-if="existingContainerSizeIdShow"
                            readonly
                            label="ContainerSize"
                            v-model="existingContainerSize"
                            :rules="rules"
                        ></v-text-field>
                        <v-text-field
                            outlined
                            dense
                            v-if="existingContainerTypeIdShow"
                            readonly
                            label="ContainerType"
                            v-model="existingContainerType"
                            :rules="rules"
                        ></v-text-field>
                        <v-select
                            outlined
                            dense
                            label="ContainerSize"
                            placeholder="ContainerSize"
                            v-if="containerSizeIdShow"
                            v-model="containerSizeId"
                            :rules="rules"
                            :items="container_sizes"
                            item-text="size"
                            item-value="container_size_id"
                        ></v-select>
                        <v-select
                            outlined
                            dense
                            label="ContainerType"
                            placeholder="ContainerType"
                            v-if="containerTypeIdShow"
                            v-model="containerTypeId"
                            :rules="rules"
                            :items="container_types"
                            item-text="type"
                            item-value="container_type_id"
                        ></v-select>
                        <!-- <v-select
                            outlined
                            dense
                            label="Destination"
                            placeholder="DestidestinaionIdnation"
                            v-model="destinaionId"
                            :rules="rules"
                            :items="destinations"
                            item-text="destination_type"
                            item-value="destination_id"
                        ></v-select> -->
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="red"
                            dark
                            @click="saveContainerInformation"
                            :disabled="disableSaveContainerInformationButton"
                        >
                            <v-icon>mdi-content-save</v-icon>
                            <v-icon>mdi-send</v-icon>
                            save & send
                        </v-btn>
                        <v-btn
                            @click="clearInputValues()"
                        >cancel</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            loading: false,
            userEmailAddress: null,
            container_sizes: [],
            container_types: [],
            // destinationMaster: [],
            // destinations: [],
            existingContainer: {},
            containerNumber: null,
            containerSizeId: null,
            existingContainerSizeId: null,
            existingContainerSize: null,
            containerTypeId: null,
            existingContainerTypeId: null,
            existingContainerType: null,
            destinaionId: null,
            containerSizeIdShow: true,
            containerTypeIdShow: true,
            existingContainerSizeIdShow: false,
            existingContainerTypeIdShow: false,
            rules: [(v) => !!v || "Required"],
            emailRules: [
                v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
            ],
            disableSaveContainerInformationButton: false,
            patterns: {},
        }
    },
    mounted() {

        if( !this.$store.state.user.name || !this.$store.state.user.organization ) {
            alert('Please provide your company name...');
            this.$router.push('/profile');
        }

        this.clearInputValues();
        this.assignInputValues();
        this.loadContainerSizes();
        this.loadContainerTypes();
        // this.loadDestinations();
    },
    methods: {
        clearInputValues: function() {
            this.userEmailAddress = null;
            this.containerNumber = null;
            this.containerSizeId = null;
            this.containerTypeId = null;
            this.existingContainerSize = null;
            this.existingContainerType = null;
            this.existingContainerSizeId = null;
            this.existingContainerTypeId = null;
            this.destinaionId = null;
            this.containerSizeIdShow = true;
            this.containerTypeIdShow = true;
            this.existingContainerSizeIdShow = false;
            this.existingContainerTypeIdShow = false;
            this.assignInputValues();
        },
        assignInputValues: function() {
            this.userEmailAddress = this.$store.state.user.email;
        },
        avoidDuplicateEntryOfContainerNumber: function() {
            this.loading = true;
            this.overlay = true;

            this.axios.post(
                '/api/avoid_duplicate_entry_of_container_number',
                {
                    container_number: this.containerNumber
                }
            ).then(response => {
                console.log(response.data)
                if(response.status === 200) {
                    if(response.data.Error) {
                        const payload = {
                            title: "[Error]Container Number Duplicate Entry",
                            msg: response.data.Error,
                        };
                        this.$store.commit("showAlert", payload);
                        this.containerNumber = null;
                    } else if(response.data.Success) {
                        const payload = {
                            title: "[Success]",
                            msg: response.data.Success,
                        };
                        this.$store.commit("showAlert", payload);
                        this.containerSizeIdShow = false;
                        this.containerTypeIdShow = false;
                        this.existingContainerSizeIdShow = true;
                        this.existingContainerTypeIdShow = true;
                        this.existingContainer = response.data.Data[0];
                        this.existingContainerSizeId = this.existingContainer.container_size_id;
                        this.existingContainerTypeId = this.existingContainer.container_type_id;

                        const sizeObj = this.container_sizes.filter((value) => {
                            return value.container_size_id == this.existingContainer.container_size_id;
                        });
                        const typeObj = this.container_types.filter((value) => {
                            return value.container_type_id == this.existingContainer.container_type_id;
                        });
                        this.existingContainerSize = sizeObj[0].size;
                        this.existingContainerType = typeObj[0].type;
                        this.containerSizeId = null;
                        this.containerTypeId = null;
                    } else if(response.data.Notice) {
                        const payload = {
                            title: "[Notice]",
                            msg: response.data.Notice,
                        };
                        this.$store.commit("showAlert", payload);
                        this.containerSizeIdShow = true;
                        this.containerTypeIdShow = true;
                        this.existingContainerSizeIdShow = false;
                        this.existingContainerTypeIdShow = false;
                        this.existingContainerSizeId = null;
                        this.existingContainerTypeId = null;
                        this.existingContainerSize = null;
                        this.existingContainerType = null;
                        this.containerSizeId = null;
                        this.containerTypeId = null;
                    }
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.loading = false;
                this.overlay = false;
            });
        },
        saveContainerInformation: function() {
            if (!this.$refs.form.validate()) {
                return false;
            }
            this.overlay = true;
            this.loading = true;
            this.disableSaveContainerInformationButton = true;

            this.axios.post(
                '/api/save_container',
                {
                created_by: this.$store.state.user.id,
                // company_id: this.$store.state.user.company_id,
                company_id: 'CLI-0007',
                email: this.userEmailAddress,
                container_number: this.containerNumber,
                container_size_id: this.containerSizeId,
                container_type_id: this.containerTypeId,
                // destination_id: this.destinaionId
                }
            ).then(response => {
                // console.log('resu');
                // console.log(response.data);
                if(response.data === 'Container Saved!') {
                    this.encodeContainerInformationDialog = false;
                    this.disableSaveContainerInformationButton = false;
                    const payload = {
                        title: "Container Saved!",
                        msg: "The information has been successfully saved in our database...",
                    };
                    this.$store.commit("showAlert", payload);
                    this.$router.push('/transactions_for_preadvise');
                } else {
                    this.encodeContainerInformationDialog = false;
                    const payload = {
                        title: "Error!",
                        msg: "Duplicate Entry Error...the transaction_id is already existing in the database table. Kindly contact with the system support...",
                    };
                    this.$store.commit("showAlert", payload);
                    this.disableSaveContainerInformationButton = false;
                }
            }).catch(error => {
                // console.log('era');
                // console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
                this.clearInputValues();
            });

        },
        upperCase: function() {
            if(!this.containerNumber) {
                return false;
            }
            this.containerNumber = this.containerNumber.toUpperCase();
            if( this.containerNumber.match(/[^A-Z0-9]/g) ) {
                this.containerNumber = this.containerNumber.replace(/[^A-Z0-9]/g, "");
            }
        },
        loadContainerTypes: function() {
            this.axios.post('/api/get_container_types')
                    .then(response => {
                        this.container_types = response.data;
                        // console.log(response.data)
                    }).catch(error => {
                        console.log(error);
                    });
        },
        loadContainerSizes: function() {
            this.axios.post('/api/get_container_sizes')
                    .then(response => {
                        this.container_sizes = response.data;
                    }).catch(error => {
                        console.log(error);
                    });
        },
        // loadDestinations: function() {
        //     this.axios.post('/api/get_destinations')
        //         .then(response => {
        //             this.destinations = response.data;
        //             this.destinationMaster = response.data;
        //         }).catch(error => {
        //             console.log(error);
        //         });
        // },
        // filterDestinationOptionsBySize: function() {


        //     const self = this;
        //     const size = this.container_sizes.filter(function(value) {
        //         return value.container_size_id == self.containerSizeId;
        //     });

        //     let ptns = [];
        //     let patterns = [];
        //     for( let dest of this.destinationMaster) {
        //         ptns = Object.values(JSON.parse(dest.patterns)).filter(function(value) {
        //             return value.active == true && value.size == size[0].size;
        //         });
        //         patterns.push(...ptns);
        //     }
        //     // console.log(patterns);

        //     this.destinations = [];
        //     for( let ptn of patterns ) {
        //         this.destinations.push({
        //             destination_id: this.getDestinationId(ptn.destination),
        //             destination_type: ptn.destination,
        //         });
        //     }
        //     // console.log(this.destinations);

        // },
        getDestinationId: function(destinationType) {
            const res = this.destinationMaster.filter(function(value) {
                return value.destination_type == destinationType;
            });
            return res[0].destination_id;
        }
    }
}
</script>
