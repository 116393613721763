<template>
    <v-container>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title>Picture File Maintenance</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-data-table
                :headers="pictureHeaders"
                :items="pictures"
                :loading="loading"
                :items-per-page="-1"
                :search="search"
                fixed-header
                height="50vh"
                ></v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            loading: false,
            statuses: [],
            pictures: [],
            search: '',
            pictureHeaders: [],
        }
    },
    mounted() {
        axios.post('/api/get_pictures_in_virtual_inspection_pictures_bucket')
        .then(response => {
            console.log(response.data);
        });
    }
}
</script>