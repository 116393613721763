<template>
  <div>

    <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
        <v-container
            class="fill-height"
            fluid
        >
            <v-row align="center" justify="center">
                <v-col cols="12" sm="8" md="4">
                    <a href="/sign_up">If you are a new user, sign up first</a>
                    <v-card
                        class="elevation-1"
                    >
                        <v-toolbar
                            class="indigo lighten-2"
                            dark
                            flat
                        >
                            <v-toolbar-title>Login Form</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-tooltip bottom>
                                <template v-slot:activator="{on}">
                                    <v-btn
                                        @click="onInstallationClick"
                                        class="warning elevation-0"
                                        dark
                                        v-on="on"
                                    >
                                        <v-icon>mdi-home-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>Add to HomeScreen</span>
                            </v-tooltip>
                        </v-toolbar>
                        <v-form ref="form">
                            <v-card-text>
                                <v-text-field
                                    label="Login"
                                    name="login"
                                    prepend-icon="person"
                                    type="text"
                                    v-model="email"
                                    :rules="emailRules"
                                ></v-text-field>
                                <v-text-field
                                    label="Password"
                                    name="password"
                                    prepend-icon="lock"
                                    type="password"
                                    v-model="password"
                                    :rules="passwordRules"
                                ></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    class="indigo lighten-2"
                                    dark
                                    block
                                    @click="auth"
                                >
                                    <v-icon>mdi-login</v-icon> Login
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="8" md="4">
                <a href="/forget">Forgot Password?</a>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="8" md="4">
                    <v-card
                            class="elevation-1"
                        >
                            <v-toolbar
                                class="indigo lighten-2"
                                dark
                                flat
                            >
                                Instruction Video for Truckers
                            </v-toolbar>
                        <v-card-text>
                            <video controls width="100%" height="100%">
                                <source src="/videos/for_truckers.mp4">
                            </video>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
  </div>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            tab: null,
            email: null,
            password: null,
            emailRules: [(v) => /.+@.+/.test(v) || "Invalid Email address"],
            passwordRules: [(v) => !!v || "Required"],
            promptEvent: null,
        }
    },
    mounted() {
        // Push.create('test');
        if(this.$store.state.isLogged) {
            location.href = './profile';
        } else {
            this.$store.commit('logOut');
        }

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/sw.js').then( (registration) => {
                console.log('ServiceWorker registration successful with scope: ', registration.scope);
            }).catch((err) => {
                console.log('ServiceWorker registration failed: ', err);
            });
        }
        window.addEventListener('beforeinstallprompt', e => {
            e.preventDefault();
            this.promptEvent = e;
        });
    },
    methods: {
        onInstallationClick: function() {
            this.promptEvent.prompt();
            this.promptEvent.userChoice
                .then(choice => {
                    if(choice.outcome === 'accepted') {
                        console.log('PWA: Installation permitted.');
                    } else {
                        console.log('PWA: Installation refuresed.');
                    }
                this.promptEvent = null;
                });
        },
        auth: function() {
            if (!this.$refs.form.validate()) {
                return false;
            }
            this.overlay = true;
            this.axios.post(
                '/api/get_token',
                {
                    email: this.email,
                    password: this.password,
                }
            ).then(response => {
                console.log(response.data);
                if (response.status === 200) {
                    if (response.data == 'NO_COMPANY_ID') {
                        const payload = {
                            title: "Please ask System Admin",
                            msg: "You seem to have no company_id in your account...",
                        };
                        this.$store.commit("showAlert", payload);
                        return false;
                    }
                    if (!response.data.user) {
                        const payload = {
                            title: "Invalid user...",
                            msg: "You provided invalid user information or your email account has not yet been verified...",
                        };
                        this.$store.commit("showAlert", payload);
                        this.overlay = false;
                        this.login = null;
                        this.password = null;
                        return false;
                    }
                        this.$store.commit('assignAccessToken', response.data.access_token);
                        this.$store.commit('logIn');
                        this.$store.commit('assignUserInfo', response.data.user);
                        location.href = '/profile';
                        this.overlay = false;
                    } else {
                        alert("Invalid user...");
                        this.login = null;
                        this.password = null;
                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                });
        },
        redirectToProvider: function(provider) {
            this.overlay = true;
            window.location.href = '/api/auth/redirect/' + provider;
        },
        underConstruction: function() {
            alert('Under Construction...');
        }
    }
}
</script>
<style scoped>

* {
  text-transform: none !important;
}

</style>
