import Vue from 'vue'
import Router from 'vue-router'
import Store from '../store/index.js'
import SignUp from '../pages/SignUp.vue'
import RegisterContainer from '../pages/RegisterContainer.vue'
import TransactionsForPreadvise from '../pages/TransactionsForPreadvise.vue'
import TransactionsForSinoCargoworks from '../pages/TransactionsForSinoCargoworks.vue'
import TransactionsForOldTsLines from '../pages/TransactionsForOldTsLines.vue'
import TransactionsForInterasiaLines from '../pages/TransactionsForInterasiaLines.vue'
import TransactionsForSitc from '../pages/TransactionsForSitc.vue'
import RejectedTransactions from '../pages/RejectedTransactions.vue'
import ContainersForSinoCargoworks from '../pages/admin/ContainersForSinoCargoworks.vue'
import ContainersForInterasiaLines from '../pages/admin2/ContainersForInterasiaLines.vue'
import ContainersForSitc from '../pages/admin2/ContainersForSitc.vue'
import TakePicture from '../pages/TakePicture.vue'
import Login from '../pages/Login.vue'
import VerifyAccount from '../pages/VerifyAccount.vue'
import Forget from '../pages/Forget.vue'
import ChangePassword from '../pages/ChangePassword.vue'
// import SendInquiry from '../pages/SendInquiry.vue'
import FollowupEmails from '../pages/su/FollowupEmails.vue'
import OtpInput from '../pages/OtpInput.vue'
import Payment from '../pages/Payment.vue'
import Test1Emails from '../pages/su/Test1Emails.vue'
import DuplicateTransactions from '../pages/su/DuplicateTransactions.vue'
import TransactionMaintenance from '../pages/su/TransactionMaintenance.vue'
import ContainerMaintenance from '../pages/su/ContainerMaintenance.vue'
import RedirectedFromDragonpay from '../pages/RedirectedFromDragonpay.vue'
import Sales from '../pages/su/Sales.vue'
import Users from '../pages/su/Users.vue'
import Areas from '../pages/su/Areas.vue'
import ContactUs from '../pages/ContactUs.vue'
import Notifications from '../pages/Notifications.vue'
import Profile from '../pages/Profile.vue'
import Destinations from '../pages/su/Destinations.vue'
import VerifyContainer from '../pages/su/VerifyContainer.vue'
import PaymentMaintenance from '../pages/su/PaymentMaintenance.vue'
import PictureFileMaintenance from '../pages/su/PictureFileMaintenance.vue'
import Statistics from '../pages/su/Statistics.vue'

Vue.use(Router)

const routes = [
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {
            isPublic: false,
        }
    },
    {
        path: '/redirect',
        name: 'RedirectedFromDragonpay',
        component: RedirectedFromDragonpay,
        meta: {
            isPublic: true,
        }
    },
    {
        path: '/su/sales',
        name: 'Sales',
        component: Sales,
        meta: {
            isPublic: true,
        }
    },
    {
        path: '/sign_up',
        name: 'SignUp',
        component: SignUp,
        meta: {
            isPublic: true
        }
    },
    {
        path: '/verify_account/:email',
        name: 'VerifyAccount',
        component: VerifyAccount,
        meta: {
            isPublic: true
        }
    },
    {
        path: '/forget',
        name: 'Forget',
        component: Forget,
        meta: {
            isPublic: true
        }
    },
    {
        path: '/change_password/:email',
        name: 'ChangePassword',
        component: ChangePassword,
        meta: {
            isPublic: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            isPublic: true
        }
    },
    {
        path: '/otp_input/:container_number/:transaction_id/:email',
        name: 'OtpInput',
        component: OtpInput,
        meta: {
            isPublic: true
        }
    },
    {
        path: '/payment/:container_number/:transaction_id/:email',
        name: 'Payment',
        component: Payment,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/register_container',
        name: 'RegisterContainer',
        component: RegisterContainer,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/transactions_for_preadvise',
        name: 'TransactionsForPreadvise',
        component: TransactionsForPreadvise,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/transactions_for_old_ts_lines',
        name: 'TransactionsForOldTsLines',
        component: TransactionsForOldTsLines,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/transactions_for_sino_cargoworks',
        name: 'TransactionsForSinoCargoworks',
        component: TransactionsForSinoCargoworks,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/transactions_for_interasia_lines',
        name: 'TransactionsForInterasiaLines',
        component: TransactionsForInterasiaLines,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/transactions_for_sitc',
        name: 'TransactionsForSitc',
        component: TransactionsForSitc,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/rejected_transactions',
        name: 'RejectedTransactions',
        component: RejectedTransactions,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/admin/containers_for_sino_cargoworks',
        name: 'ContainersForSinoCargoworks',
        component: ContainersForSinoCargoworks,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/admin2/containers_for_interasia_lines',
        name: 'ContainersForInterasiaLines',
        component: ContainersForInterasiaLines,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/admin2/containers_for_sitc',
        name: 'ContainersForSitc',
        component: ContainersForSitc,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/su/test1_emails',
        name: 'Test1Emails',
        component: Test1Emails,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/su/destinations',
        name: 'Destinations',
        component: Destinations,
        meta: {
            isPublic: false
        }
    },
    // {
    //     path: '/send_inquiry',
    //     name: 'SendInquiry',
    //     component: SendInquiry,
    //     meta: {
    //         isPublic: false
    //     }
    // },
    {
        path: '/take_picture/:container_number/:transaction_id/:email',
        name: 'TakePicture',
        component: TakePicture,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/su/verify_container/:transaction_id',
        name: 'VerifyContainer',
        component: VerifyContainer,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/su/users',
        name: 'Users',
        component: Users,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/su/areas',
        name: 'Areas',
        component: Areas,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/su/duplicate_transactions',
        name: 'DuplicateTransactions',
        component: DuplicateTransactions,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/su/transaction_maintenance',
        name: 'TransactionMaintenance',
        component: TransactionMaintenance,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/su/container_maintenance',
        name: 'ContainerMaintenance',
        component: ContainerMaintenance,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/su/payment_maintenance',
        name: 'PaymentMaintenance',
        component: PaymentMaintenance,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/su/picture_file_maintenance',
        name: 'PictureFileMaintenance',
        component: PictureFileMaintenance,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/su/followup_emails',
        name: 'FollowupEmails',
        component: FollowupEmails,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/contact_us',
        name: 'ContactUs',
        component: ContactUs,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/notifications',
        name: 'Notifications',
        component: Notifications,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/su/statistics',
        name: 'Statistics',
        component: Statistics,
        meta: {
            isPublic: false
        }
    },
];

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.path.match(/\/su\//)) {
        if (Store.state.user.role != 'su' && Store.state.user.role != 'surveyor') {
            alert('You are not allowed to enter the area...');
            return false;
        }
    }

    if (to.path.match(/\/admin\//)) {
        if ((Store.state.user.company_id != 'CLI-0017' || Store.state.user.role != 'admin') && Store.state.user.role != 'su') {
            alert('You are not allowed to enter the area...');
            // alert(Store.state.user.company_id);
            return false;
        }
    }

    if (to.path.match(/\/admin2\//)) {
        if ((Store.state.user.company_id != 'CLI-0018' || Store.state.user.role != 'admin') && Store.state.user.role != 'su') {
            alert('You are not allowed to enter the area...');
            // alert(Store.state.user.company_id);
            return false;
        }
    }

    if (to.matched.some(page => page.meta.isPublic || Store.state.isLogged)) {
        next()
    } else {
        next('/login')
    }
});

export default router
