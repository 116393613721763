<template>
    <div>
        <v-container>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card class="mx-auto px-2 pt-2">
                <v-toolbar flat dark class="indigo lighten-3">
                    <v-toolbar-title>Duplicate Transactions</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <!-- <v-btn
                        block
                        color="red"
                        class="lighten-3"
                        dark
                        @click="deleteDuplicateTransactions()"
                    >Delete Duplicate Transaction Record(s)</v-btn> -->
                    <v-data-table
                    :headers="headers"
                    :items="transactions"
                    :loading="loading"
                    :items-per-page="3000"
                    fixed-header
                    height="50vh"
                    hide-default-footer
                    >
                    <template v-slot:top>
                        # of records: <span style="color: red; font-weight: bold;">{{transactions.length}}</span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="warning"
                                    class="lighten-2"
                                    icon
                                    v-on="on"
                                    @click="openDuplicateTransactionsDialog(item)"
                                >
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>
                            </template>
                            <span>Open Dialog</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
                </v-card-text>
            </v-card>
        </v-container>
        <!--duplicateTransactionsDialog start-->
        <v-dialog v-model="duplicateTransactionsDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Delete Duplicate Transaction(s)</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="duplicateTransactionsDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-data-table
                            :headers="duplicateTransactionHeaders"
                            :items="duplicateTransactions"
                            :loading="loading"
                            :items-per-page="3000"
                            fixed-header
                            height="50vh"
                            hide-default-footer
                        >
                            <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at | formatDate }}
                            </template>
                            <template v-slot:[`item.updated_at`]="{ item }">
                                {{ item.updated_at | formatDate }}
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            color="red"
                                            class="lighten-2"
                                            v-on="on"
                                            @click="deleteDuplicateTransaction(item)"
                                        >mdi-delete</v-icon>
                                    </template>
                                    <span>Delete Item</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
        <!--duplicateTransactionsDialog end-->
    </div>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            loading: false,
            headers: [
                {
                    text: "container_number",
                    value: "container_number",
                    sortable: true,
                },
                {
                    text: "count",
                    value: "count",
                    sortable: true,
                },
                {
                    text: "Actions",
                    value: "actions",
                    sortable: false,
                },
            ],
            transactions: [],
            duplicateTransactionsDialog: false,
            duplicateTransactionHeaders: [
                {
                    text: "TransactionId",
                    value: "transaction_id",
                    sortable: true,
                },
                {
                    text: "container_number",
                    value: "container_number",
                    sortable: true,
                },
                {
                    text: "Email",
                    value: "email",
                    sortable: true,
                },
                {
                    text: "StatusId",
                    value: "status_id",
                    sortable: true,
                },
                {
                    text: "CreatedAt",
                    value: "created_at",
                    sortable: true,
                },
                {
                    text: "UpdatedAt",
                    value: "updated_at",
                    sortable: true,
                },
                {
                    text: "Actions",
                    value: "actions",
                    sortable: false,
                },
            ],
            duplicateTransactions: []
        }
    },
    created() {
        this.getDuplicateTransactions();
    },
    methods: {
        getDuplicateTransactions: function () {
            this.overlay = true;
            this.loading = true;
            axios.post(
                '/api/get_duplicate_transactions',
            {
            }
            ).then(response => {
                this.transactions = response.data;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        openDuplicateTransactionsDialog: function(item) {
            this.duplicateTransactionsDialog = true;
            this.overlay = true;
            this.loading = true;
            axios.post(
                '/api/get_specific_duplicate_transactions',
                {
                    container_number: item.container_number
                }
            ).then(response => {
                this.duplicateTransactions = response.data;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        deleteDuplicateTransaction: function(item) {
            if(!confirm('Is it ok to delete the item?')) {
                return false;
            }

            this.overlay = true;
            this.loading = true;
            axios.post(
                '/api/delete_duplicate_transaction',
                {
                    id: item.id
                }
            ).then(response => {
                this.duplicateTransactionsDialog = false;
                this.getDuplicateTransactions();
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        deleteDuplicateTransactions: function() {
            // this.overlay = true;
            // this.loading = true;
            // axios.post(
            //     '/api/delete_duplicate_transactions',
            // {
            // }
            // ).then(response => {
            //     // this.transactions = response.data;
            //     // this.getDuplicateTransactions();
            //     console.log(response.data);
            // }).catch(error => {
            //     console.log(error);
            // }).finally(() => {
            //     this.overlay = false;
            //     this.loading = false;
            // });
        }
    },
    filters: {
        formatDate: function (date) {
            return date !== null ? moment(date).format("MM-DD-YYYY hh:mm:ss A") : '';
        },
    },
}
</script>
